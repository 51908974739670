module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Allar síður',
  'Assisting Verifiers': 'Aðstoða sannprófendur',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'Get ekki hlaðið GeoAreas',
  'Cannot retrieve Critical Risks': 'Get ekki sótt gagnrýna áhættu',
  'Cannot retrieve Go To list': 'Ekki hægt að sækja Go To lista',
  'Cannot retrieve company list.': 'Ekki hægt að sækja fyrirtækjaskrá.',
  'Cannot retrieve map overlays': 'Ekki hægt að sækja yfirborð korts',
  'Chinese, Simplified': 'Kínverska, einfölduð',
  'Chinese, Simplified (简化字)': 'Kínverska, einfölduð (简化字)',
  'Coach': 'Þjálfarinn',
  'Company Structure': 'Uppbygging fyrirtækisins',
  'Compliant Verification': 'Samræmd staðfesting',
  'Compliant Verification Group': 'Samhæfður staðfestingarhópur',
  'Confined Spaces': 'Lokað rými',
  'Contact with Electricity': 'Hafðu samband við rafmagn',
  'Contact with Molten Material': 'Snerting við bráðið efni',
  'Coordinates': 'Hnit',
  'Critical Control': 'Gagnrýnisstjórnun',
  'Critical Control Status': 'Staða gagnrýnna stjórnunar',
  'Critical Risk': 'Mikilvæg áhætta',
  'Date Range': 'Dagsetning svið',
  'Drowning': 'Drukkna',
  'Dutch (Nederlands)': 'Hollenskir (Nederlands)',
  'English (English)': 'Enska (English)',
  'Entanglement and Crushing': 'Flækjum og troðningi',
  'Entanglement in Rotating Equipment': 'Flækjum í snúningsbúnaði',
  'Error': 'Villa',
  'Event in a Confined Space': 'Atburður í lokuðu rými',
  'Explosives Handling': 'Meðhöndlun sprengiefna',
  'Exposure to Chemicals that Burn': 'Útsetning fyrir efnum sem brenna',
  'Exposure to Hazardous Substances': 'Útsetning fyrir hættulegum efnum',
  'Exposure to Hazardous Substances (Chronic)': 'Útsetning fyrir hættulegum efnum (langvarandi)',
  'Exposure to Thermal Extremes': 'Útsetning fyrir hitauppstreymi',
  'Fall from Height': 'Falla frá hæð',
  'Fall of Ground - Surface': 'Fall jarðar - yfirborð',
  'Fall of Ground - Underground': 'Fall jarðar - Neðanjarðar',
  'Falling Objects': 'Fallandi hlutir',
  'Filter by Company Structure': 'Sía eftir uppbyggingu fyrirtækisins',
  'Filter by Critical Risk': 'Sía eftir mikilvægri áhættu',
  'Filter by Date': 'Sía eftir staðfestingardegi',
  'Filter by GeoArea': 'Sía eftir GeoArea',
  'Filter by Site': 'Sía eftir síðu',
  'Filter by Site Level': 'Sía eftir vefsvæði',
  'Filter by Verification Type': 'Sía eftir staðfestingartegund',
  'French (Français)': 'Frönsku (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'þýska (Deutsch)',
  'Go': 'Farðu',
  'Goto': 'Faratil',
  'Goto Coordinates': 'Faratil Hhnit',
  'Goto GeoArea': 'Faratil GeoArea',
  'Grizzly Bear Interactions': 'Samskipti Grizzly Bear',
  'Hazardous Material': 'Hættulegt efni',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Þung ökutæki viðburður - Veltu þér, Yfir Brún',
  'Icelandic (Íslensku)': 'Íslensku (Íslensku)',
  'Incident Date': 'Atvik dagsetning',
  'Incident Details': 'Upplýsingar um atvik',
  'Incident During Tire Handling': 'Atvik við meðhöndlun dekkja',
  'Italian (Italiano)': 'Ítalska (Italiano)',
  'Landscape': 'Landslag',
  'Language': 'Tungumál',
  'Legend': 'Sagan',
  'Level': 'Stig',
  'Lifting Operations': 'Lyftingaraðgerðir',
  'Loading Map Data': 'Hleður',
  'Location': 'Staðsetning',
  'Logout': 'að skrá þig út',
  'Malaria': 'Malaría',
  'Manager Verification': 'Staðfesting stjórnanda',
  'Message': 'Skilaboð',
  'Mongolian (Монгол хэл)': 'Mongólska (Монгол хэл)',
  'Mooring Operations': 'Viðlegukantar',
  'No incident results found. Please try changing the date range.': 'Engar niðurstöður fundust um atvik. Vinsamlegast reyndu að breyta tímabilinu.',
  'No verification results found. Please try changing the date range.': 'Engar staðfestingar niðurstöður fundust. Vinsamlegast reyndu að breyta tímabilinu.',
  'Non Compliant Verification': 'Sannprófun ekki í samræmi',
  'Non Compliant Verification Group': 'Staðfestingarhópur sem ekki er í samræmi við',
  'Norwegian (Norsk)': 'Norsku (Norsk)',
  'Operator Task Based Verification': 'Verkefni byggð á rekstraraðila',
  'Operator Verification': 'Staðfesting rekstraraðila',
  'Orientation': 'Stefnumörkun',
  'Paper Size': 'Pappírsstærð',
  'Physical Location': 'Líkamleg staðsetning',
  'Please note: Changing these options prepares the map for printing.': 'Vinsamlegast athugið: Að breyta þessum valkostum undirbýr kortið fyrir prentun.',
  'Portrait': 'Andlitsmynd',
  'Portuguese (Português)': 'Portúgalska (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Atvik með orku',
  'Potential Fatality Incident - Zero Energy': 'Atvik með núllorku',
  'Print': 'Prenta',
  'Print Data': 'Prenta gögn',
  'Print Map': 'Prenta þessa síðu',
  'Print Options': 'Prentvalkostir',
  'Print Preview': 'Sýnishorn prentunar',
  'Rail Collision': 'Járnbrautarárekstur',
  'Rail Impact on Person': 'Áhrif járnbrautar á mann',
  'Repeated Potential Fatality Incident - Energy': 'Endurtaktu atvik með orku',
  'Repeated Potential Fatality Incident - Zero Energy': 'Endurtaktu atvik með núllorku',
  'Russian (русский)': 'Rússneskt (русский)',
  'Select / Deselect All': 'Veldu / afvalið allt',
  'Select a Company Structure': 'eldu uppbyggingu fyrirtækisins',
  'Select a GeoArea': 'Veldu GeoArea',
  'Select a Site': 'Veldu síðu',
  'Serbian (Српски)': 'Serbneska (Српски)',
  'Site': 'Vefsvæði',
  'Slope Failure': 'Bilun í halla',
  'Sorry, we couldn\'t load incidents. Please try reloading the page': 'Því miður gátum við ekki hlaðið uppá atvik. Vinsamlegast reyndu að endurhlaða síðuna',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Því miður gátum við ekki hlaðið staðfestingar. Vinsamlegast reyndu að endurhlaða síðuna',
  'Spanish (Español)': 'Spænska (Español)',
  'Struck by Falling Object': 'Lenti af fallandi hlut',
  'Supervisor Task Based Verification': 'Verkefni byggð á leiðbeinendum',
  'Supervisor Verification': 'Staðfesting eftirlitsmanns',
  'Surface': 'Yfirborð',
  'US Legal': 'US Löglegur',
  'US Letter': 'US Bréf',
  'Unauthorised': 'Óleyfilegt',
  'Uncontrolled Load During Lifting': 'Stjórnlaust álag meðan á lyftingum stendur',
  'Uncontrolled Release of Energy': 'Stjórnlaus losun orku',
  'Underground Fire': 'Neðanjarðar eldur',
  'Underground Fire or Explosion': 'Neðanjarðar eldur eða sprenging',
  'Underground Hazardous Atmosphere': 'Neðanjarðar hættulegt andrúmsloft',
  'Underground Hoisting': 'Neðanjarðar hífa',
  'Underground Inrush': 'Neðanjarðar hrun',
  'Underground Rock Fall': 'Neðanjarðar rokk haust',
  'Unplanned Initiation of Explosives': 'Óskipulögð upphaf sprengiefna',
  'Vehicle Collision (Heavy and Light)': 'Árekstur ökutækja (Þungur og léttur)',
  'Vehicle Collision or Rollover': 'Árekstur ökutækja eða veltingur',
  'Vehicle Impact on Person': 'Áhrif ökutækis á mann',
  'Vehicle Incident': 'Ökutæki atvik',
  'Vehicle Pedestrian Interaction - Surface': 'Samspil gangandi vegfarenda - yfirborð',
  'Vehicle Pedestrian Interaction - Underground': 'Samskipti gangandi vegfarenda - neðanjarðar',
  'Verification Date': 'Staðfestingardagsetning',
  'Verification Details': 'Staðfestingarupplýsingar',
  'Verification Type': 'Staðfestingargerð',
  'Verifier': 'Sannprófandi',
  'View Incident': 'Skoða atvik',
  'View Verification': 'Skoða staðfestingu',
  'Warning': 'Viðvörun',
  'Finnish (Suomalainen)': 'Finlandaise (Suomalainen)',
  'You do not have access to this application. Please contact your site administrator.': 'Þú hefur ekki aðgang að þessu forriti. Vinsamlegast hafðu samband við stjórnanda vefsins.',
  'You will have to repeat these settings once you hit "Print" below': 'Þú verður að endurtaka þessar stillingar þegar þú smellir á „Prenta“ hér að neðan',
  'Indonesian (Bahasa Indonesia)': 'Indónesíska (Bahasa Indonesia)'
};
