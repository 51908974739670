module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'All Sites',
  'Assisting Verifiers': 'Assisting Verifiers',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'Cannot load GeoAreas',
  'Cannot retrieve Critical Risks': 'Cannot retrieve Critical Risks',
  'Cannot retrieve Go To list': 'Cannot retrieve Go To list',
  'Cannot retrieve company list.': 'Cannot retrieve company list.',
  'Cannot retrieve map overlays': 'Cannot retrieve map overlays',
  'Chinese, Simplified': 'Chinese, Simplified',
  'Chinese, Simplified (简化字)': 'Chinese, Simplified (简化字)',
  'Coach': 'Coach',
  'Company Structure': 'Company Structure',
  'Compliant Verification': 'Compliant Verification',
  'Compliant Verification Group': 'Compliant Verification Group',
  'Confined Spaces': 'Confined Spaces',
  'Contact with Electricity': 'Contact with Electricity',
  'Contact with Molten Material': 'Contact with Molten Material',
  'Coordinates': 'Coordinates',
  'Critical Control': 'Critical Control',
  'Critical Control Status': 'Critical Control Status',
  'Critical Risk': 'Critical Risk',
  'Date Range': 'Date Range',
  'Drowning': 'Drowning',
  'Dutch (Nederlands)': 'Dutch (Nederlands)',
  'English (English)': 'English (English)',
  'Entanglement and Crushing': 'Entanglement and Crushing',
  'Entanglement in Rotating Equipment': 'Entanglement in Rotating Equipment',
  'Error': 'Error',
  'Event in a Confined Space': 'Event in a Confined Space',
  'Explosives Handling': 'Explosives Handling',
  'Exposure to Chemicals that Burn': 'Exposure to Chemicals that Burn',
  'Exposure to Hazardous Substances': 'Exposure to Hazardous Substances',
  'Exposure to Hazardous Substances (Chronic)': 'Exposure to Hazardous Substances (Chronic)',
  'Exposure to Thermal Extremes': 'Exposure to Thermal Extremes',
  'Fall from Height': 'Fall from Height',
  'Fall of Ground - Surface': 'Fall of Ground - Surface',
  'Fall of Ground - Underground': 'Fall of Ground - Underground',
  'Falling Objects': 'Falling Objects',
  'Filter by Company Structure': 'Filter by Company Structure',
  'Filter by Critical Risk': 'Filter by Critical Risk',
  'Filter by Date': 'Filter by Verification Date',
  'Filter by GeoArea': 'Filter by GeoArea',
  'Filter by Site': 'Filter by Site',
  'Filter by Site Level': 'Filter by Site Level',
  'Filter by Verification Type': 'Filter by Verification Type',
  'French (Français)': 'French (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'German (Deutsch)',
  'Go': 'Go',
  'Goto': 'Goto',
  'Goto Coordinates': 'Goto Coordinates',
  'Goto GeoArea': 'Goto GeoArea',
  'Grizzly Bear Interactions': 'Grizzly Bear Interactions',
  'Hazardous Material': 'Hazardous Material',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Heavy Vehicle Event - Rollover, Over Edge',
  'Icelandic (Íslensku)': 'Icelandic (Íslensku)',
  'Incident Date': 'Incident Date',
  'Incident Details': 'Incident Details',
  'Incident During Tire Handling': 'Incident During Tire Handling',
  'Italian (Italiano)': 'Italian (Italiano)',
  'Landscape': 'Landscape',
  'Language': 'Language',
  'Legend': 'Legend',
  'Level': 'Level',
  'Lifting Operations': 'Lifting Operations',
  'Loading Map Data': 'Loading',
  'Location': 'Location',
  'Logout': 'Logout',
  'Malaria': 'Malaria',
  'Manager Verification': 'Manager Verification',
  'Message': 'Message',
  'Mongolian (Монгол хэл)': 'Mongolian (Монгол хэл)',
  'Mooring Operations': 'Mooring Operations',
  'No incident results found. Please try changing the date range.': 'No incident results found. Please try changing the date range.',
  'No verification results found. Please try changing the date range.': 'No verification results found. Please try changing the date range.',
  'Non Compliant Verification': 'Non Compliant Verification',
  'Non Compliant Verification Group': 'Non Compliant Verification Group',
  'Norwegian (Norsk)': 'Norwegian (Norsk)',
  'Operator Task Based Verification': 'Operator Task Based Verification',
  'Operator Verification': 'Operator Verification',
  'Orientation': 'Orientation',
  'Paper Size': 'Paper Size',
  'Physical Location': 'Physical Location',
  'Please note: Changing these options prepares the map for printing.': 'Please note: Changing these options prepares the map for printing.',
  'Portrait': 'Portrait',
  'Portuguese (Português)': 'Portuguese (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Incident with Energy',
  'Potential Fatality Incident - Zero Energy': 'Incident with Zero Energy',
  'Print': 'Print',
  'Print Data': 'Print Data',
  'Print Map': 'Print this page',
  'Print Options': 'Print Options',
  'Print Preview': 'Print Preview',
  'Rail Collision': 'Rail Collision',
  'Rail Impact on Person': 'Rail Impact on Person',
  'Repeated Potential Fatality Incident - Energy': 'Repeat Incident with Energy',
  'Repeated Potential Fatality Incident - Zero Energy': 'Repeat Incident with Zero Energy',
  'Russian (русский)': 'Russian (русский)',
  'Select / Deselect All': 'Select / Deselect All',
  'Select a Company Structure': 'Select a Company Structure',
  'Select a GeoArea': 'Select a GeoArea',
  'Select a Site': 'Select a Site',
  'Serbian (Српски)': 'Serbian (Српски)',
  'Site': 'Site',
  'Slope Failure': 'Slope Failure',
  'Sorry, we couldn\'t load incidents. Please try reloading the page': 'Sorry, we couldn\'t load incidents. Please try reloading the page',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Sorry, we couldn\'t load verifications. Please try reloading the page',
  'Spanish (Español)': 'Spanish (Español)',
  'Struck by Falling Object': 'Struck by Falling Object',
  'Supervisor Task Based Verification': 'Supervisor Task Based Verification',
  'Supervisor Verification': 'Supervisor Verification',
  'Surface': 'Surface',
  'US Legal': 'US Legal',
  'US Letter': 'US Letter',
  'Unauthorised': 'Unauthorised',
  'Uncontrolled Load During Lifting': 'Uncontrolled Load During Lifting',
  'Uncontrolled Release of Energy': 'Uncontrolled Release of Energy',
  'Underground Fire': 'Underground Fire',
  'Underground Fire or Explosion': 'Underground Fire or Explosion',
  'Underground Hazardous Atmosphere': 'Underground Hazardous Atmosphere',
  'Underground Hoisting': 'Underground Hoisting',
  'Underground Inrush': 'Underground Inrush',
  'Underground Rock Fall': 'Underground Rock Fall',
  'Unplanned Initiation of Explosives': 'Unplanned Initiation of Explosives',
  'Vehicle Collision (Heavy and Light)': 'Vehicle Collision (Heavy and Light)',
  'Vehicle Collision or Rollover': 'Vehicle Collision or Rollover',
  'Vehicle Impact on Person': 'Vehicle Impact on Person',
  'Vehicle Incident': 'Vehicle Incident',
  'Vehicle Pedestrian Interaction - Surface': 'Vehicle Pedestrian Interaction - Surface',
  'Vehicle Pedestrian Interaction - Underground': 'Vehicle Pedestrian Interaction - Underground',
  'Verification Date': 'Verification Date',
  'Verification Details': 'Verification Details',
  'Verification Type': 'Verification Type',
  'Verifier': 'Verifier',
  'View Incident': 'View Incident',
  'View Verification': 'View Verification',
  'Warning': 'Warning',
  'Finnish (Suomalainen)': 'Finnish (Suomalainen)',
  'Indonesian (Bahasa Indonesia)': 'Indonesian (Bahasa Indonesia)',
  'You do not have access to this application. Please contact your site administrator.': 'You do not have access to this application. Please contact your site administrator.',
  'You will have to repeat these settings once you hit "Print" below': 'You will have to repeat these settings once you hit "Print" below',
};
