module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Tous les sites',
  'Assisting Verifiers': 'Aider à Verifier',
  'COVID-19 (Coronavirus)': 'COVID-19 (coronavirus)',
  'Cannot load GeoAreas': 'Impossible de charger GeoAreas',
  'Cannot retrieve Critical Risks': 'Impossible de récupérer les risques critiques',
  'Cannot retrieve Go To list': 'Impossible de récupérer la liste Aller à',
  'Cannot retrieve company list.': 'Impossible de récupérer la liste des entreprises.',
  'Cannot retrieve map overlays': 'Impossible de récupérer les superpositions de carte',
  'Chinese, Simplified': 'Chinois simplifié',
  'Chinese, Simplified (简化字)': 'Chinois simplifié (简化字)',
  'Coach': 'Entraîneur',
  'Company Structure': 'Structure de l\'entreprise',
  'Compliant Verification': 'Vérification conforme',
  'Compliant Verification Group': 'Groupe de vérification conforme',
  'Confined Spaces': 'Espace clos',
  'Contact with Electricity': 'Contact avec une source d\'électricité',
  'Contact with Molten Material': 'Contact avec des matières en fusion',
  'Coordinates': 'Les coordonnées',
  'Critical Control': 'Contrôle critique',
  'Critical Control Status': 'Statut de contrôle critique',
  'Critical Risk': 'Risque critique',
  'Date Range': 'Plage de dates',
  'Drowning': 'Noyade',
  'Dutch (Nederlands)': 'hollandais (Nederlands)',
  'English (English)': 'Anglais (English)',
  'Entanglement and Crushing': 'Enchevêtrement et écrasement',
  'Entanglement in Rotating Equipment': 'Enchevêtrement dans l\'équipement rotatif',
  'Error': 'Erreur',
  'Event in a Confined Space': 'Événement dans un espace confiné',
  'Explosives Handling': 'Manipulation d\'explosifs',
  'Exposure to Chemicals that Burn': 'Exposition aux produits chimiques combustibles',
  'Exposure to Hazardous Substances': 'Exposition à des substances dangereuses',
  'Exposure to Hazardous Substances (Chronic)': 'Exposition à des substances dangereuses (effets chroniques sur la santé)',
  'Exposure to Thermal Extremes': 'Exposition aux températures extrêmes',
  'Fall from Height': 'Chute de hauteur',
  'Fall of Ground - Surface': 'Chute de sol - Surface',
  'Fall of Ground - Underground': 'Chute de terrain - Souterraine',
  'Falling Objects': 'Chutes d\'objets',
  'Filter by Company Structure': 'Filtrer par structure d\'entreprise',
  'Filter by Critical Risk': 'Filtrer par risque critique',
  'Filter by Date': 'Filtrer par date',
  'Filter by GeoArea': 'Filtrer par GeoArea',
  'Filter by Site': 'Filtrer par site',
  'Filter by Site Level': 'Filtrer par niveau de site',
  'Filter by Verification Type': 'Filtrer par type de vérification',
  'French (Français)': 'Français (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Allemand (Deutsch)',
  'Go': 'Aller',
  'Goto': 'Aller à',
  'Goto Coordinates': 'Aller aux coordonnées',
  'Goto GeoArea': 'Accédez à GeoArea',
  'Grizzly Bear Interactions': 'Interactions avec le grizzli',
  'Hazardous Material': 'Matériel dangereux',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Événement de véhicules lourds - renversement, au-dessus du bord',
  'Icelandic (Íslensku)': 'Islandais (Íslensku)',
  'Incident Date': 'Date de l\'incident',
  'Incident Details': 'Détails de l\'incident',
  'Incident During Tire Handling': 'Incident lors de la manipulation des pneus',
  'Italian (Italiano)': 'Italien (Italiano)',
  'Landscape': 'Paysage',
  'Language': 'Langues',
  'Legend': 'Légende',
  'Level': 'Niveau',
  'Lifting Operations': 'Opérations de levage',
  'Loading Map Data': 'Chargement des données cartographiques',
  'Location': 'Emplacement',
  'Logout': 'Se déconnecter',
  'Malaria': 'Paludisme',
  'Manager Verification': 'Vérifications du gestionnaire',
  'Message': 'Message',
  'Mongolian (Монгол хэл)': 'Mongol (Монгол хэл)',
  'Mooring Operations': 'Opérations d’amarrage',
  'No incident results found. Please try changing the date range.': 'Aucun résultat d\'incident trouvé. Essayez de changer la plage de dates.',
  'No verification results found. Please changing the date range.': 'Aucun résultat de vérification trouvé. S\'il vous plaît changer la plage de dates.',
  'Non Compliant Verification': 'Vérification non conforme',
  'Non Compliant Verification Group': 'Groupe de vérification non conforme',
  'Norwegian (Norsk)': 'Norvégien (Norsk)',
  'Operator Task Based Verification': 'Vérification basée sur la tâche de l\'opérateur',
  'Operator Verification': 'Vérification de l\'opérateur',
  'Orientation': 'Orientation',
  'Paper Size': 'Taille de papier',
  'Physical Location': 'Lieu de travail',
  'Please note: Changing these options prepares the map for printing.': 'Remarque: la modification de ces options prépare la carte pour l\'impression.',
  'Portrait': 'Portrait',
  'Portuguese (Português)': 'Portugais (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Incident avec Énergie',
  'Potential Fatality Incident - Zero Energy': 'Incident avec Énergie Zéro',
  'Print': 'Impression',
  'Print Data': 'Données d\'impression',
  'Print Map': 'Imprimer la carte',
  'Print Options': 'Options d\'impression',
  'Print Preview': 'Aperçu avant impression',
  'Rail Collision': 'Collision ferroviaire',
  'Rail Impact on Person': 'Impact d\'un train sur une personne',
  'Repeated Potential Fatality Incident - Energy': "Répéter l'incident avec l'énergie",
  'Repeated Potential Fatality Incident - Zero Energy': "Répéter l'incident avec zéro énergie",
  'Russian (русский)': 'Russe (русский)',
  'Select / Deselect All': 'Sélectionner / Désélectionner tout',
  'Select a Company Structure': 'Sélectionnez une structure d\'entreprise',
  'Select a GeoArea': 'Sélectionnez une zone géographique',
  'Select a Site': 'Sélectionnez un site',
  'Serbian (Српски)': 'Serbe (Српски)',
  'Site': 'Emplacement',
  'Slope Failure': 'Rupture de talus',
  'Sorry, we couldn\'t load incidents. Please try reloading the page.': 'Désolé, nous n\'avons pas pu charger d\'incidents. Essayez de recharger la page.',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Désolé, nous n\'avons pas pu charger les vérifications. S\'il vous plaît essayez de recharger la page',
  'Spanish (Español)': 'Espagnol (Español)',
  'Struck by Falling Object': 'Frappé par la chute d\'un objet',
  'Supervisor Task Based Verification': 'Vérification basée sur les tâches du superviseur',
  'Supervisor Verification': 'Vérifications du superviseur',
  'Surface': 'Surface',
  'US Legal': 'US Légal',
  'US Letter': 'Lettre US',
  'Unauthorised': 'Non autorisé',
  'Uncontrolled Load During Lifting': 'Charge incontrôlée pendant le levage',
  'Uncontrolled Release of Energy': 'Décharge incontrôlée d\'énergie',
  'Underground Fire': 'Incendie souterrain',
  'Underground Fire or Explosion': 'Incendie ou explosion souterraine',
  'Underground Hazardous Atmosphere': 'Atmosphères souterraines dangereuses',
  'Underground Hoisting': 'Levage souterrain',
  'Underground Inrush': 'Irruption souterraine',
  'Underground Rock Fall': 'Chute de pierres souterraine',
  'Unplanned Initiation of Explosives': 'Amorçage d\'explosifs imprévu',
  'Vehicle Collision (Heavy and Light)': 'Collision de véhicules (lourds et légers)',
  'Vehicle Collision or Rollover': 'Collision ou renversement de véhicule',
  'Vehicle Impact on Person': 'Impact d\'un véhicule sur une personne',
  'Vehicle Incident': 'Incident de véhicule',
  'Vehicle Pedestrian Interaction - Surface': 'Interaction avec les piétons - Surface',
  'Vehicle Pedestrian Interaction - Underground': 'Interaction avec les piétons - Métro',
  'Verification Date': 'Date de vérification',
  'Verification Details': 'Détails de vérification',
  'Verification Type': 'Type de vérification',
  'Verifier': 'Vérifier',
  'View Incident': 'Voir l\'incident',
  'View Verification': 'Voir la vérification',
  'Warning': 'avertissement',
  'Finnish (Suomalainen)': 'Finnska (Suomalainen)',
  'Indonesian (Bahasa Indonesia)': 'Indonésienne (Bahasa Indonesia)',
  'You do not have access to this application. Please contact your site administrator': 'Vous n\'avez pas accès à cette application. Merci de contacter l\'administrateur de votre site',
  'You will have to repeat these settings once you hit "Print" below': 'Vous devrez répéter ces réglages une fois que vous avez appuyé sur "Imprimer" ci-dessous',
};
