export default (state = {}, action) => {
  switch (action.type) {
    case 'AUTH_SET':
    case 'AUTH_UPDATE':
    case 'MINI_AUTH_SET':
      const {type, ...authData} = action;
      return {
        ...state,
        ...authData,
      };

    case 'SET_UNAUTHORISED':
      return {
        ...state,
        username: action.username,
        unauthorised: true,
      };

    case 'AUTH_CLEAR':
      return {};

    default:
      return state;
  }
};
