import moment from 'moment';

import en from '../Translations/en';
import {verificationTypes} from '../utils/filters';
import config from '../Config/index.js';

let criticalRisks = state => state.map.risks;

const default_date_range = config.default_date_range
  ? config.default_date_range
  : 3;

export default () => {
  return {
    auth: {},
    print: {
      orientation: 'portrait',
      length: 297, // size = the size in mm of big side. eg. a4 = 297
      isPrinting: false,
      printType: 'map',
    },
    modal: false,
    map: {
      initialZoom: null,
      initialCenter: null,
      companyId: false,
      visibleGeoAreas: false,
      companySites: [],
      risks: {},
      verifications: {
        byId: {},
        compliantIds: [],
        nonCompliantIds: [],
      },
      incidents: {
        byId: {},
        pfieIds: [],
        pfierIds: [],
        pfizeIds: [],
        pfizerIds: [],
        pfiefIds: [],
        pfierfIds: []
      },
      geoAreas: {},
      loading: {},
      spotData: {},
      spotSensitivity: 3,
      siteLevelsVisible: [1, 2, 3, 4, 5, 6, 7],
      bounds: {
        latMin: -90,
        latMax: 90,
        longMin: -180,
        longMax: 180,
      },
      groupsVisible: [
        'compliant',
        'nonCompliant',
        'pfie',
        'pfier',
        'pfize',
        'pfizer',
        'pfief',
        'pfierf',
        'blackspot',
        'hotspot',
      ],
      criticalRisksVisible: Object.keys(criticalRisks).map(k => criticalRisks[k]),
      verificationTypesVisible: verificationTypes,
      date: {
        from: moment()
          .subtract(default_date_range, 'days')
          .format('YYYY-MM-DD'),
        to: moment()
          .format('YYYY-MM-DD'),
      },
      defaultDates: {
        from: moment()
          .subtract(default_date_range, 'days')
          .format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      },
      company_sites: [],
      selected_company_site: '',
      goto: null,
    },
    ui: {
      width: 768,
      isMobile: false,
      showNavMenu: false,
      showFilterMenu: false,
      navMenuEnabled: false,
      filterMenuEnabled: true,
    },
    language: {
      locale: 'en',
      t: en,
    },
    overlays: {
      overlays: {},
      geoAreasByAreaCode: null,
      mapBounds: null,
      visibleDepths: ['Surface'],
    },
  };
};
