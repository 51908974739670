import {connect} from 'react-redux';

import Component from '../Components/ResponsiveLayout';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setWidth(width) {
      dispatch({type: 'UI_SET_WIDTH', width});
    },
    setDpcm(dpcm) {
      /* Not needed or used, but left in as could be useful in future */
      dispatch({type: 'UI_SET_DPCM', dpcm});
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
