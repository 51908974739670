import React from 'react';
import {Layout, Icon, Affix, Button, Collapse} from 'antd';

import Text from './Text';

/*
 * These components are all quite heavy because of their Ant Design dependencies.
 * They're lazy loaded to save initial load time. You will see a lot of conditional
 * rendering below so code is only loaded when headers are clicked on
 */

const Legend = React.lazy(() => import('../Containers/Legend'));
const SiteSelector = React.lazy(() => import('../Containers/SiteSelector'));
const SelectDateRange = React.lazy(() =>
  import('../Containers/SelectDateRange'),
);
const CompanySiteFilter = React.lazy(() =>
  import('../Containers/CompanySiteFilter'),
);
const CriticalRiskOptions = React.lazy(() =>
  import('../Containers/CriticalRiskOptions'),
);
const VerificationTypeOptions = React.lazy(() =>
  import('../Containers/VerificationTypeOptions'),
);
const GeoAreaFilter = React.lazy(() => import('../Containers/GeoAreaFilter'));

const {Sider} = Layout;
const {Panel} = Collapse;

export default props => {
  const {
    isMobile,
    toggleFilterMenu,
    showFilterMenu,
    showCompanySiteFilter,
    companySiteFilterLoading,
    isUnauthorised,
    isReady,
  } = props;

  /* Unauthorised page shouldn't show the sidebar at all */
  if (isUnauthorised) return null;

  /* The button shown in bottom-right corner on mobile */
  const toggleMenu = isMobile ? (
    <Affix
      style={{position: 'absolute', bottom: 10, right: 10, zIndex: 100000002}}>
      <Button
        onClick={toggleFilterMenu}
        size="large"
        shape="circle"
        type={showFilterMenu ? 'secondary' : 'primary'}
        icon="filter"
      />
    </Affix>
  ) : null;

  const filterMenu =
    !isMobile || showFilterMenu ? (
      <Sider
        width={isMobile ? '100%' : 360}
        className="filter-sidebar"
        theme="light"
        style={
          isMobile
            ? {
                position: 'absolute',
                overflow: 'auto',
                bottom: 30,
                zIndex: 100000000,
                boxSizing: 'border-box',
                left: 0,
              }
            : {}
        }>
        <Collapse accordion={true} defaultActiveKey={['date']} bordered={false}>
          <Panel header={<Text>Filter by Company Structure</Text>} key="site">
            <React.Suspense fallback={<span>...</span>}>
              <SiteSelector />
            </React.Suspense>
          </Panel>
          {showCompanySiteFilter === true && (
            <Panel
              header={
                <span>
                  <Text>Filter by Site</Text>
                  {/* Requires data from an API so doesn't load straight away */}
                  {companySiteFilterLoading === true && (
                    <Icon
                      type="loading"
                      style={{fontSize: 18, margin: '0 0 0 10px'}}
                      spin
                    />
                  )}
                </span>
              }
              key="company_site"
              className={'company_site_filter'}>
              <CompanySiteFilter />
            </Panel>
          )}
          <Panel header={<Text>Filter by GeoArea</Text>} key="geoarea">
            <React.Suspense fallback={<span>...</span>}>
              {isReady && <GeoAreaFilter />}
            </React.Suspense>
          </Panel>
          <Panel header={<Text>Filter by Date</Text>} key="date">
            <React.Suspense fallback={<span>...</span>}>
              {isReady && <SelectDateRange />}
            </React.Suspense>
          </Panel>
          <Panel header={<Text>Filter by Critical Risk</Text>} key="risk">
            <React.Suspense fallback={<span>...</span>}>
              <CriticalRiskOptions />
            </React.Suspense>
          </Panel>
          <Panel header={<Text>Filter by Verification Type</Text>} key="type">
            <React.Suspense fallback={<span>...</span>}>
              <VerificationTypeOptions />
            </React.Suspense>
          </Panel>
          <Panel header={<Text>Legend</Text>} key="legend">
            <React.Suspense fallback={<span>...</span>}>
              <Legend />
            </React.Suspense>
          </Panel>
        </Collapse>
      </Sider>
    ) : null;

  return (
    <React.Fragment>
      {filterMenu}
      {toggleMenu}
    </React.Fragment>
  );
};
