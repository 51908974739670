/*
 * There is a sporadic bug without this middleware that means sometimes the map
 * is rendered in only a small section in the top-left corner of the screen.
 * This happens because the map is shown before the app is fully rendered.
 * This fix works by simply listening for AUTH_SET, and waiting a second before
 * letting the map know it's ok to load. THis doesn't slow down app as there
 * is a wait anyway as initial API requests are made
 */

export default store => next => action => {
  next(action);
  if (action.type === 'SET_COMPANY') {
    if (global.isTest) return store.dispatch({type: 'MAP_READY'});

    setTimeout(() => store.dispatch({type: 'MAP_READY'}), 1000);
  }
};
