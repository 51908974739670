import {createStore, combineReducers, applyMiddleware} from 'redux';

import initial from './initial';
import map from '../reducers/map';
import ui from '../reducers/ui';
import auth from '../reducers/auth';
import print from '../reducers/print';
import modal from '../reducers/modal';
import language from '../reducers/language';
import overlays from '../reducers/overlays';
import api from '../middleware/api';
import authMiddleware from '../middleware/auth';
import localStorage from '../middleware/localStorage';
import printMiddleware from '../middleware/print';
import notifications from '../middleware/notifications';
import mapReady from '../middleware/mapReady';

const reducers = combineReducers({
  map,
  ui,
  auth,
  language,
  print,
  modal,
  overlays,
});
const middleware = applyMiddleware(
  api,
  localStorage,
  authMiddleware,
  printMiddleware,
  notifications,
  mapReady,
);

export default createStore(reducers, initial(), middleware);
