import React from 'react';
import throttle from 'lodash/throttle';

/*
 * This has been built as a class rather than a "pure" function so that
 * we can access the React lifecycle methods "componentDidMount" (on init) and
 * "componentWillUnmount" (on destroyed).
 *
 * It works by listening for browser resize events, and setting the browser
 * width to the store, so components can work out if the user is on mobile or
 * not. This is like "media queries for Javascript".
 *
 * From a rendering point of view, this simply renders it's children.
 */
export default class ResponsiveLayout extends React.Component {
  componentDidMount() {
    this.saveViewportDimensions(false);
    window.addEventListener('resize', this.saveViewportDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.saveViewportDimensions);
  }

  /*
   * If a user drags their screen to resize it it will fire resize events
   * rapidly, causing every component that depends on the ui.width value in the
   * store to rerender each time. We get around this by using lodash's throttle,
   * which only fires the function at most once every 100ms.
   *
   * We have the timeout set to a thousand (1s), because it fixes print issues
   * in IE11. Normally, a timeout of 0 - pushing the event to the end of
   * Javascript's event queue - would do the trick, but IE plays by it's own
   * rules. We have a timeout of 0 on first load so user doesn't get a flash
   * of non-mobile content on mobile.
   */
  saveViewportDimensions = throttle(needsDelay => {
    /*
     * Below line is not needed, but left in because it might come in handy
     * for fixing printing stuff
     */
    // this.props.setDpcm(document.getElementById('dpi').offsetHeight);

    setTimeout(
      () => this.props.setWidth(window.innerWidth),
      needsDelay ? 1000 : 0,
    );
  }, 100);

  render() {
    return this.props.children;
  }
}
