module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Todos os sites',
  'Assisting Verifiers': 'Assitência de verificadores',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'Não é possível carregar o GeoAreas',
  'Cannot retrieve Critical Risks': 'Não é possível recuperar riscos críticos',
  'Cannot retrieve Go To list': 'Não pode recuperar Ir para lista',
  'Cannot retrieve company list.': 'Não é possível recuperar a lista de empresas.',
  'Cannot retrieve map overlays': 'Não é possível recuperar sobreposições de mapa',
  'Chinese, Simplified': 'chinês simplificado',
  'Chinese, Simplified (简化字)': 'Chinês (简化字)',
  'Coach': 'Treinador',
  'Company Structure': 'Estrutura da companhia',
  'Compliant Verification': 'Verificação em conformidade',
  'Compliant Verification Group': 'Grupo de verificação em conformidade',
  'Confined Spaces': 'Espaços confinados',
  'Contact with Electricity': 'Contato com Eletricidade',
  'Contact with Molten Material': 'Contato com material fundido',
  'Coordinates': 'Coordenadas',
  'Critical Control': 'Controle Crítico',
  'Critical Control Status': 'Status de Controle Crítico',
  'Critical Risk': 'Risco Crítico',
  'Date Range': 'Range de datas',
  'Drowning': 'Afogamento',
  'Dutch (Nederlands)': 'as língua holandesa (Nederlands)',
  'English (English)': 'inglês (English)',
  'Entanglement and Crushing': 'Emaranhamento e esmagamento',
  'Entanglement in Rotating Equipment': 'Emaranhamento em equipamento rotativo',
  'Error': 'Erro',
  'Event in a Confined Space': 'Evento em Espaço Confinado',
  'Explosives Handling': 'Manuseio de explosivos',
  'Exposure to Chemicals that Burn': 'Queimadura Química',
  'Exposure to Hazardous Substances': 'Exposição à Substâncias Perigosas',
  'Exposure to Hazardous Substances (Chronic)': 'Exposição à Substâncias Perigosas (Chronic)',
  'Exposure to Thermal Extremes': 'Exposição a Extremos Térmicos',
  'Fall from Height': 'Cair da altura',
  'Fall of Ground - Surface': 'Queda do solo - superfície',
  'Fall of Ground - Underground': 'Queda do Solo - Subterrânea',
  'Falling Objects': 'Objetos que caem',
  'Filter by Company Structure': 'Filtrar por estrutura da empresa',
  'Filter by Critical Risk': 'Filtrar por Risco Crítico',
  'Filter by Date': 'Filtrar por data',
  'Filter by GeoArea': 'Filtrar por GeoArea',
  'Filter by Site': 'Filtrar por site',
  'Filter by Site Level': 'Filtrar por nível do site',
  'Filter by Verification Type': 'Filtrar por tipo de verificação',
  'French (Français)': 'francês (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Alemão (Deutsch)',
  'Go': 'Vai',
  'Goto': 'Vamos para',
  'Goto Coordinates': 'Ir para coordenadas',
  'Goto GeoArea': 'Vamos para GeoArea',
  'Grizzly Bear Interactions': 'Interações do urso pardo',
  'Hazardous Material': 'Material perigoso',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Evento de veículo pesado - capotamento, acima da borda',
  'Icelandic (Íslensku)': 'islandês (Íslensku)',
  'Incident Date': 'Data do incidente',
  'Incident Details': 'Detalhes do incidente',
  'Incident During Tire Handling': 'Incidente durante o manuseio de pneus',
  'Italian (Italiano)': 'Italiano (Italiano)',
  'Landscape': 'Paisagem',
  'Language': 'Idiomas',
  'Legend': 'lenda',
  'Level': 'Nível',
  'Lifting Operations': 'Operações de elevação',
  'Loading Map Data': 'Carregando dados do mapa',
  'Location': 'Localização',
  'Logout': 'Logout',
  'Malaria': 'Malária',
  'Manager Verification': 'Verificação do gerente',
  'Message': 'Mensagem',
  'Mongolian (Монгол хэл)': 'mongol (Монгол хэл)',
  'Mooring Operations': 'Operações de Amarração',
  'No incident results found. Please try changing the date range.': 'Nenhum resultado de incidente encontrado. Por favor, tente alterar o intervalo de datas.',
  'No verification results found. Please changing the date range.': 'Nenhum resultado de verificação encontrado. Por favor, altere o intervalo de datas.',
  'Non Compliant Verification': 'Verificação não conforme',
  'Non Compliant Verification Group': 'Grupo de Verificação Não Conforme',
  'Norwegian (Norsk)': 'Norueguês (Norsk)',
  'Operator Task Based Verification': 'Verificação baseada na tarefa do operador',
  'Operator Verification': 'Verificação do Operador',
  'Orientation': 'Orientação',
  'Paper Size': 'Tamanho do papel',
  'Physical Location': 'Localização física',
  'Please note: Changing these options prepares the map for printing.': 'Por favor, note: Alterar estas opções prepara o mapa para impressão',
  'Portrait': 'Retrato',
  'Portuguese (Português)': 'Português (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Incidente de Fatalidade Potencial - Energia',
  'Potential Fatality Incident - Zero Energy': 'Incidente de Fatalidade Potencial - Energia Zero',
  'Print': 'Imprimir',
  'Print Data': 'Imprimir dados',
  'Print Map': 'Imprimir mapa',
  'Print Options': 'Opções de impressão',
  'Print Preview': 'Visualização da impressão',
  'Rail Collision': 'Colisão ferroviária',
  'Rail Impact on Person': 'Impacto ferroviário na pessoa',
  'Repeated Potential Fatality Incident - Energy': 'Repita o incidente com energia',
  'Repeated Potential Fatality Incident - Zero Energy': 'Repita o incidente com energia zero',
  'Russian (русский)': 'russo (русский)',
  'Select / Deselect All': 'Selecionar / desmarcar tudo',
  'Select a Company Structure': 'Selecione uma estrutura da empresa',
  'Select a GeoArea': 'Selecione uma área geográfica',
  'Select a Site': 'Selecione um site',
  'Serbian (Српски)': 'Sérvio (Српски)',
  'Site': 'Local',
  'Slope Failure': 'Falha de talude',
  'Sorry, we couldn\'t load incidents. Please try reloading the page.': 'Desculpe, não foi possível carregar incidentes. Por favor, tente recarregar a página.',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Desculpe, não foi possível carregar as verificações. Por favor, tente recarregar a página',
  'Spanish (Español)': 'espanhol (Español)',
  'Struck by Falling Object': 'Atingido por um objeto caindo',
  'Supervisor Task Based Verification': 'Verificação baseada em tarefas do supervisor',
  'Supervisor Verification': 'Verificação de supervisor',
  'Surface': 'Superfície',
  'US Legal': 'Jurídico dos EUA',
  'US Letter': 'Carta dos EUA',
  'Unauthorised': 'Não autorizado',
  'Uncontrolled Load During Lifting': 'Carga descontrolada durante o levantamento',
  'Uncontrolled Release of Energy': 'Liberação descontrolada de energia',
  'Underground Fire': 'Fogo subterrâneo',
  'Underground Fire or Explosion': 'Incêndio ou explosão subterrânea',
  'Underground Hazardous Atmosphere': 'Atmosfera perigosa subterrânea',
  'Underground Hoisting': 'Elevação subterrâneo',
  'Underground Inrush': 'Influxo subterrâneo',
  'Underground Rock Fall': 'Queda de rocha subterrânea',
  'Unplanned Initiation of Explosives': 'Iniciação não planejada de explosivos',
  'Vehicle Collision (Heavy and Light)': 'Colisão de veículo (pesado e leve)',
  'Vehicle Collision or Rollover': 'Capotamento e Colisão de Veículo',
  'Vehicle Impact on Person': 'Veículo impacta em Pessoa',
  'Vehicle Incident': 'Incidente de veículo',
  'Vehicle Pedestrian Interaction - Surface': 'Interação com pedestres em veículos - superfície',
  'Vehicle Pedestrian Interaction - Underground': 'Interação com pedestres em veículos - Subterrânea',
  'Verification Date': 'Data da Verificação',
  'Verification Details': 'Detalhes de verificação',
  'Verification Type': 'Tipo de verificação',
  'Verifier': 'Verificador',
  'View Incident': 'Visualizar incidente',
  'View Verification': 'Ver verificação',
  'Warning': 'Atenção',
  'Finnish (Suomalainen)': 'Finlandesa (Suomalainen)',
  'You do not have access to this application. Please contact your site administrator': 'Você não tem acesso a este aplicativo. Por favor, entre em contato com o administrador do seu site',
  'You will have to repeat these settings once you hit "Print" below': 'Você terá que repetir estas configurações uma vez que você pressione "imprimir" abaixo',
  'Indonesian (Bahasa Indonesia)': 'Indonésia (Bahasa Indonesia)'
};
