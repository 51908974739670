/*
 * When adding a new icon, grab it from here:https://gist.githubusercontent.com/sunel/6070165724b02d4d35bb8c46a63105ff/raw/58a5fd045e8d4738505cad3d67108a65b9c67e95/icon.js
 */

export {
  default as CloseOutline,
} from '@ant-design/icons/lib/outline/CloseOutline';
export {
  default as AlertOutline,
} from '@ant-design/icons/lib/outline/AlertOutline';
export {
  default as LinkOutline,
} from '@ant-design/icons/lib/outline/LinkOutline';
export {
  default as CalendarOutline,
} from '@ant-design/icons/lib/outline/CalendarOutline';
export {
  default as CheckCircleOutline,
} from '@ant-design/icons/lib/outline/CheckCircleOutline';
export {
  default as CheckSquareOutline,
} from '@ant-design/icons/lib/outline/CheckSquareOutline';
export {
  default as CloseCircleOutline,
} from '@ant-design/icons/lib/outline/CloseCircleOutline';
export {
  default as CloseSquareOutline,
} from '@ant-design/icons/lib/outline/CloseSquareOutline';
export {
  default as DeleteOutline,
} from '@ant-design/icons/lib/outline/DeleteOutline';
export {
  default as FilterOutline,
} from '@ant-design/icons/lib/outline/FilterOutline';
export {
  default as EditOutline,
} from '@ant-design/icons/lib/outline/EditOutline';
export {
  default as ExclamationCircleOutline,
} from '@ant-design/icons/lib/outline/ExclamationCircleOutline';
export {
  default as InfoCircleOutline,
} from '@ant-design/icons/lib/outline/InfoCircleOutline';
export {
  default as NotificationOutline,
} from '@ant-design/icons/lib/outline/NotificationOutline';
export {
  default as PrinterOutline,
} from '@ant-design/icons/lib/outline/PrinterOutline';
export {
  default as ArrowDownOutline,
} from '@ant-design/icons/lib/outline/ArrowDownOutline';
export {
  default as ArrowLeftOutline,
} from '@ant-design/icons/lib/outline/ArrowLeftOutline';
export {
  default as ArrowRightOutline,
} from '@ant-design/icons/lib/outline/ArrowRightOutline';
export {
  default as ArrowUpOutline,
} from '@ant-design/icons/lib/outline/ArrowUpOutline';
export {
  default as RightOutline,
} from '@ant-design/icons/lib/outline/RightOutline';
export {default as UpOutline} from '@ant-design/icons/lib/outline/UpOutline';
export {
  default as DownOutline,
} from '@ant-design/icons/lib/outline/DownOutline';
export {
  default as UserOutline,
} from '@ant-design/icons/lib/outline/UserOutline';
export {
  default as GlobalOutline,
} from '@ant-design/icons/lib/outline/GlobalOutline';
export {
  default as LoadingOutline,
} from '@ant-design/icons/lib/outline/LoadingOutline';
export {
  default as ThunderboltOutline,
} from '@ant-design/icons/lib/outline/ThunderboltOutline';
export {
  default as CaretDownOutline,
} from '@ant-design/icons/lib/outline/CaretDownOutline';
export {
  default as CaretLeftOutline,
} from '@ant-design/icons/lib/outline/CaretLeftOutline';
export {
  default as CaretRightOutline,
} from '@ant-design/icons/lib/outline/CaretRightOutline';
export {
  default as CaretUpOutline,
} from '@ant-design/icons/lib/outline/CaretUpOutline';
export {
  default as CaretDownFill,
} from '@ant-design/icons/lib/fill/CaretDownFill';

export {
  default as EyeOutline,
} from '@ant-design/icons/lib/outline/EyeOutline';

export {
  default as CloseCircleFill,
} from '@ant-design/icons/lib/fill/CloseCircleFill';

export {
  default as EnvironmentOutline,
} from '@ant-design/icons/lib/outline/EnvironmentOutline';
