export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_OVERLAYS':
      /*
       * Sorted in ascending order.
       * This code ensures that only most recent uploads are rendered
       */

      var overlays = [];

      if(typeof action.overlays !== 'undefined' && action.overlays.length > 0) {
        const overlayMap = action.overlays
          .sort((a, b) => {
            return (
              new Date(a.lastModified).getTime() -
              new Date(b.lastModified).getTime()
            );
          })
          .reduce((obj, overlay) => {
            overlay.geoAreas.forEach(geoArea => {
              obj[geoArea.physicalLocationId] = {
                ...overlay,
                geoArea,
              };
            });

            return obj;
          }, {});

        /* Now flatten back to array */
        overlays = Object.keys(overlayMap).map(k => overlayMap[k]);
      }

      return {
        ...state,
        overlays
      };

    case 'SET_MAP_BOUNDS':
      return {
        ...state,
        mapBounds: action.bounds,
        zoom: action.zoom,
      };
      break;

    case 'TOGGLE_DEPTH_VISIBILITY':
      return {
        ...state,
        visibleDepths:
          state.visibleDepths.indexOf(action.depth) > -1
            ? state.visibleDepths.filter(d => d !== action.depth)
            : [...state.visibleDepths, action.depth],
      };

    default:
      return state;
  }
};

function bySiteId(obj, geoArea) {
  return {
    ...obj,
    [geoArea.areaCode]: formatGeoArea(geoArea),
  };
}

function formatGeoArea(geoArea) {
  return {
    id: geoArea.areaCode + '',
    coords: JSON.parse(geoArea.geoJson).geometry.coordinates[0].map(c => [c[1], c[0]]),
  };
}
