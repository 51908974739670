import React from 'react';
import {Spin} from 'antd';

import Text from './Text';

export default () => {
  return (
    <div className="loading">
      <div>
        <Spin />
        <span>
          <Text>Loading Map Data</Text>...
        </span>
      </div>
    </div>
  );
};
