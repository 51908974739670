module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Бүх уурхайн талбар',
  'Assisting Verifiers': 'Магадлагаа хамтран хийсэн хүн',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'GeoAreas-г ачаалах боломжгүй байна',
  'Cannot retrieve Critical Risks': 'Чухал эрсдлүүдийг татаж авах боломжгүй байна',
  'Cannot retrieve Go To list': 'Go To жагсаалтыг авч чадахгүй байна',
  'Cannot retrieve company list.': 'Компанийн жагсаалтыг гаргаж чадахгүй байна',
  'Cannot retrieve map overlays': 'Газрын зургийн давхаргыг татаж авах боломжгүй',
  'Chinese, Simplified': 'Хятад, Хялбаршуулсан',
  'Chinese, Simplified (简化字)': 'Хятад, Хялбаршуулсан (简化字)',
  'Coach': 'Дасгалжуулагч',
  'Company Structure': 'Компанийн бүтэц',
  'Compliant Verification': 'Нийцсэн магадлагаа',
  'Compliant Verification Group': 'Гүйцэтгэлийн баталгаажуулалтын бүлэг',
  'Confined Spaces': 'Хязгаарлагдмал орчин',
  'Contact with Electricity': 'Цахилгаан гүйдэлд өртөх',
  'Contact with Molten Material': 'Хайлсан материалтай холбоо бариарай',
  'Coordinates': 'Координатууд',
  'Critical Control': 'Ноцтой эрсдэлийн хяналт',
  'Critical Control Status': 'Чухал хяналтын төлөв байдал',
  'Critical Risk': 'Ноцтой эрсдэл',
  'Date Range': 'Огноо Range',
  'Drowning': 'Живэх эрсдэл',
  'Dutch (Nederlands)': 'Голланд хэл (Nederlands)',
  'English (English)': 'Англи (English)',
  'Entanglement and Crushing': 'Тээглэж орооцолдох, хавчуулагдах',
  'Entanglement in Rotating Equipment': 'Эргэдэг тоног төхөөрөмжид орлуулах',
  'Error': 'Алдаа',
  'Event in a Confined Space': 'Хязгаарлагдмал орон зайд болох үйл явдал',
  'Explosives Handling': 'Тэсрэх бодис боловсруулах',
  'Exposure to Chemicals that Burn': 'Шатаж буй химийн бодисын өртөлт',
  'Exposure to Hazardous Substances': 'Аюултай химийн бодист өртөх',
  'Exposure to Hazardous Substances (Chronic)': 'Аюултай Хортой Бодисын Урт Хугацааны Өртөлт',
  'Exposure to Thermal Extremes': 'Хэт халуун, хүйтний үйлчлэлд өртөх',
  'Fall from Height': 'Өндрөөс унах',
  'Fall of Ground - Surface': 'Газрын уналт - Гадаргуу',
  'Fall of Ground - Underground': 'Талбайн уналт - Газар доорх',
  'Falling Objects': 'Дээрээс биет унах',
  'Filter by Company Structure': 'Компанийн бүтцийн дагуу шүүгд',
  'Filter by Critical Risk': 'Гол эрсдэлд шүүмжлэ',
  'Filter by Date': 'Огноо байдлаар шүү',
  'Filter by GeoArea': 'гэхэд шүүлтүүр GeoArea',
  'Filter by Site': 'Сайтаар шүү',
  'Filter by Site Level': 'Сайтын түвшингээс шүү',
  'Filter by Verification Type': 'Баталгаажилтын төрлөөр шүүх',
  'French (Français)': 'Франц хэл (Франц) (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Герман хэл (Deutsch)',
  'Go': 'Явцгаая',
  'Goto': 'Руу явах',
  'Goto Coordinates': 'Goto координатууд',
  'Goto GeoArea': 'Руу явах GeoArea',
  'Grizzly Bear Interactions': 'Grizzly баавгайн харилцан үйлчлэл',
  'Hazardous Material': 'Аюултай материал',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Хүнд даацын тээврийн хэрэгсэл - Ролловер, Хажуугийн дээгүүр',
  'Icelandic (Íslensku)': 'Исланд хэл (Íslensku)',
  'Incident Date': 'Осол гарсан огноо',
  'Incident Details': 'Ослын тухай дэлгэрэнгүй мэдээлэл',
  'Incident During Tire Handling': 'Дугуйтай харьцах явцад гарсан осол',
  'Italian (Italiano)': 'Итали хэл (Italiano)',
  'Landscape': 'Ландшафт',
  'Language': 'Хэлнүүд',
  'Legend': 'Тайлбар',
  'Level': 'Түвшин',
  'Lifting Operations': 'Өргөлтийн ажил',
  'Loading Map Data': 'Газрын зургийн мэдээллийг ачаалж байна',
  'Location': 'Байршил',
  'Logout': 'Гарах',
  'Malaria': 'Хумхаа',
  'Manager Verification': 'Менежер Баталгаажуулалт',
  'Message': 'Зурвас',
  'Mongolian (Монгол хэл)': 'Монгол хэл (Монгол хэл)',
  'Mooring Operations': 'Модны үйл ажиллагаа',
  'No incident results found. Please try changing the date range.': 'Гэмт хэргийн үр дүн олдсонгүй. Өдрийн хязгаарыг өөрчлөхийг оролдоно уу',
  'No verification results found. Please changing the date range.': 'Баталгаажуулалтын үр дүн олдсонгүй. Өдрийн хязгаарыг өөрчилнө үү.',
  'Non Compliant Verification': 'Үл нийцсэн магадлагаа',
  'Non Compliant Verification Group': 'Гүйцэтгэлийн баталгаажуулалтын бус групп',
  'Norwegian (Norsk)': 'Норвегийн Нинорск',
  'Operator Task Based Verification': 'Операторын ажилд үндэслэсэн баталгаажуулалт',
  'Operator Verification': 'Оператор баталгаажуулалт',
  'Orientation': 'Зүг чиг',
  'Paper Size': 'Цаасны хэмжээ',
  'Physical Location': 'Байршил',
  'Please note: Changing these options prepares the map for printing.': 'Анхаарна уу: Эдгээр сонголтыг өөрчлөх нь газрын зургийг хэвлэхэд бэлтгэдэг.',
  'Portrait': 'Хөрөг',
  'Portuguese (Português)': 'Португал (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Амь нас эрсдэж болзошгүй байсан ослууд -Энергийн үйлчлэлтэй',
  'Potential Fatality Incident - Zero Energy': 'Амь нас эрсдэж болзошгүй байсан ослууд -Энергийн үйлчлэлгүй',
  'Print': 'Хэвлэх',
  'Print Data': 'Өгөгдөл хэвлэх',
  'Print Map': 'Хэвлэх газрын зураг',
  'Print Options': 'Хэвлэх сонголтууд',
  'Print Preview': 'Хэвлэх байдал',
  'Rail Collision': 'Төмөр замын мөргөлдөөн',
  'Rail Impact on Person': 'Төмөр замын хувь хүний нөлөөлөл',
  'Repeated Potential Fatality Incident - Energy': 'Эрчим хүчээр ослыг давт',
  'Repeated Potential Fatality Incident - Zero Energy': 'Тэг энергитэй ослыг давт',
  'Russian (русский)': 'Орос (русский)',
  'Select / Deselect All': 'Бүгдийг сонгох / хасах',
  'Select a Company Structure': 'Компанийн бүтэцийг сонгох',
  'Select a GeoArea': 'GeoArea-г сонгоно уу',
  'Select a Site': 'Сайт сонгоно уу',
  'Serbian (Српски)': 'Серб (Српски)',
  'Site': 'Сайт',
  'Slope Failure': 'Налуугийн нуралт',
  'Sorry, we couldn\'t load incidents. Please try reloading the page.': 'Уучлаарай, бид осол зөрчиж чадсангүй. Хуудсыг дахин ачаалж үзнэ үү.',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Уучлаарай, бид баталгаажуулалтыг ачаалж чадсангүй. Хуудсыг дахин ачаалж үзнэ үү',
  'Spanish (Español)': 'Испани (Español)',
  'Struck by Falling Object': 'Унасан объектоор цохисон',
  'Supervisor Task Based Verification': 'Адил хянагч Ажлын байран дээр үндэслэсэн баталгаажуулалт',
  'Supervisor Verification': 'Судлаач Баталгаажуулалт',
  'Surface': 'Гадаргуу',
  'US Legal': 'АНУ-ын хууль эрх зүйн',
  'US Letter': 'АНУ-ын захидал',
  'Unauthorised': 'Зөвшөөрөлгүй байна',
  'Uncontrolled Load During Lifting': 'Өргөх явцад хяналтгүй ачаалал',
  'Uncontrolled Release of Energy': 'Энерги хяналтгүй чөлөөлөгдөх',
  'Underground Fire': 'Гүний уурхайд гал гарах',
  'Underground Fire or Explosion': 'Газар доорхи гал эсвэл дэлбэрэлт',
  'Underground Hazardous Atmosphere': 'Гүний уурхайн аюултай хий',
  'Underground Hoisting': 'Гүний уурхайн өргөх төхөөрөмж',
  'Underground Inrush': 'Гүний уурхайн үер',
  'Underground Rock Fall': 'Гүний уурхайд нуралт үүсэх',
  'Unplanned Initiation of Explosives': 'Тэсрэх бодисын гэнэтийн тэсрэлт',
  'Vehicle Collision (Heavy and Light)': 'Автомашины мөргөлдөх (хүнд ба хөнгөн)',
  'Vehicle Collision or Rollover': 'Тээврийн хэрэгсэл мөргөлдөх, онхолдох',
  'Vehicle Impact on Person': 'Хүн тээврийн хэрэгсэлд мөргүүлэх',
  'Vehicle Incident': 'Тээврийн хэрэгслийн осол',
  'Vehicle Pedestrian Interaction - Surface': 'Тээврийн хэрэгслийн явган хүний гарц',
  'Vehicle Pedestrian Interaction - Underground': 'Тээврийн хэрэгслийн явган хүний гарц',
  'Verification Date': 'Магадлагаа хийсэн огноо',
  'Verification Details': 'Баталгаажуулалтын дэлгэрэнгүй',
  'Verification Type': 'Магадлагааны төрөл',
  'Verifier': 'Баталгаажуулагч',
  'View Incident': 'Асуудлыг харах',
  'View Verification': 'Баталгаажуулалтыг харах',
  'Warning': 'Анхааруулга',
  'Finnish (Suomalainen)': 'Финлянд (Suomalainen)',
  'You do not have access to this application. Please contact your site administrator': 'Та энэ аппликешнд хандах эрхгүй байна. Сайтын админтай холбогдоно уу',
  'You will have to repeat these settings once you hit "Print" below': 'Та доорх "Хэвлэх" товчийг дарахад эдгээр тохиргоог давтах шаардлагатай болно.',
  'Indonesian (Bahasa Indonesia)': 'Индонез (Bahasa Indonesia)'
};
