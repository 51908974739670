module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Alle Sites',
  'Assisting Verifiers': 'Assisterende Verificateurs',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'Kan GeoAreas niet laden',
  'Cannot retrieve Critical Risks': 'Kan kritieke risico\'s niet ophalen',
  'Cannot retrieve Go To list': 'Kan Go To-lijst niet ophalen',
  'Cannot retrieve company list.': 'Kan bedrijfslijst niet ophalen.',
  'Cannot retrieve map overlays': 'Kan kaartoverlays niet ophalen',
  'Chinese, Simplified': 'Versimpeld Chinees',
  'Chinese, Simplified (简化字)': 'Versimpeld Chinees (简化字)',
  'Coach': 'Coach',
  'Company Structure': 'Bedrijfsstructuur',
  'Compliant Verification': 'Nalevend Verificatie',
  'Compliant Verification Group': 'Nalevend Verificatiegroep',
  'Confined Spaces': 'Besloten Ruimtes',
  'Contact with Electricity': 'Contact met Elektriciteit',
  'Contact with Molten Material': 'Contact met Gesmolten Materiaal',
  'Coordinates': 'Coördinaten',
  'Critical Control': 'Cruciale Regeling',
  'Critical Control Status': 'Cruciale Regelingstatus',
  'Critical Risk': 'Kritiek risico',
  'Date Range': 'Datumbereik',
  'Drowning': 'Verdrinkend',
  'Dutch (Nederlands)': 'Nederlands (Nederlands)',
  'English (English)': 'Engels (English)',
  'Entanglement and Crushing': 'Verwikkeling en Vernietiging',
  'Entanglement in Rotating Equipment': 'Verstrikking in roterende apparatuur',
  'Error': 'Foutmelding',
  'Event in a Confined Space': 'Evenement in een besloten ruimte',
  'Explosives Handling': 'Behandeling van explosieven',
  'Exposure to Chemicals that Burn': 'Blootstelling aan Chemicaliën die Branden',
  'Exposure to Hazardous Substances': 'Blootstelling aan Gevaarlijke Stoffen',
  'Exposure to Hazardous Substances (Chronic)': 'Blootstelling aan gevaarlijke stoffen (chronisch)',
  'Exposure to Thermal Extremes': 'Blootstelling aan extreme temperaturen',
  'Fall from Height': 'Val van Hoogte',
  'Fall of Ground - Surface': 'Val van de grond - oppervlak',
  'Fall of Ground - Underground': 'Val van de grond - Ondergronds',
  'Falling Objects': 'Vallende Voorwerpen',
  'Filter by Company Structure': 'Filteren op Bedrijfsstructuur',
  'Filter by Critical Risk': 'Filteren op Cruciaal Risico',
  'Filter by Date': 'Filteren op Verificatiedatum',
  'Filter by GeoArea': 'Filteren op GeoArea',
  'Filter by Site': 'Filteren op Site',
  'Filter by Site Level': 'Filteren op Siteniveau',
  'Filter by Verification Type': 'Filteren op Verificatietype',
  'French (Français)': 'Frans (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Duitse (Deutsch)',
  'Go': 'Gaan',
  'Goto': 'Ga naar',
  'Goto Coordinates': 'Goto-coördinaten',
  'Goto GeoArea': 'Ga naar GeoArea',
  'Grizzly Bear Interactions': 'Grizzly Bear-interacties',
  'Hazardous Material': 'Gevaarlijk materiaal',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Zwaar voertuig evenement - Rollover,over de rand',
  'Icelandic (Íslensku)': 'IJslands (Íslensku)',
  'Incident Date': 'Incidentdatum',
  'Incident Details': 'Incidentdetails',
  'Incident During Tire Handling': 'Incident tijdens bandenbehandeling',
  'Italian (Italiano)': 'Italiaans (Italiano)',
  'Landscape': 'Liggend',
  'Language': 'Taal',
  'Legend': 'Legende',
  'Level': 'Niveau',
  'Lifting Operations': 'Tiloperaties',
  'Loading Map Data': 'Aan het opladen',
  'Location': 'Locatie',
  'Logout': 'Uitloggen',
  'Malaria': 'Malaria',
  'Manager Verification': 'Manager Verificatie',
  'Message': 'Bericht',
  'Mongolian (Монгол хэл)': 'Mongools (Монгол хэл)',
  'Mooring Operations': 'Aanmeren',
  'No incident results found. Please try changing the date range.': 'Geen incidentresultaten gevonden. Tracht het datumbereik te wijzigen.',
  'No verification results found. Please try changing the date range.': 'Geen verificatieresultaten gevonden. Tracht het datumbereik te wijzigen.',
  'Non Compliant Verification': 'Niet-nalevend Verificatie',
  'Non Compliant Verification Group': 'Niet-nalevend Verificatiegroep',
  'Norwegian (Norsk)': 'Noors (Norsk)',
  'Operator Task Based Verification': 'Operator Taakgebaseerde Verificatie',
  'Operator Verification': 'Operator Verificatie',
  'Orientation': 'Oriëntatie',
  'Paper Size': 'Papiergrootte',
  'Physical Location': 'Fysieke Locatie',
  'Please note: Changing these options prepares the map for printing.': 'Merk op: Het wijzigen van deze opties bereidt de map voor op afdrukken.',
  'Portrait': 'Staand',
  'Portuguese (Português)': 'Portugees (Português, Brazilië)',
  'Potential Fatality Incident - Energy': 'Incident met Energie',
  'Potential Fatality Incident - Zero Energy': 'Incident met Nul Energie',
  'Print': 'Afdrukken',
  'Print Data': 'Druk Gegevens af',
  'Print Map': 'Druk deze pagina af',
  'Print Options': 'Druk Opties af',
  'Print Preview': 'Voorprojectie Afdruk',
  'Rail Collision': 'Spoorbotsing',
  'Rail Impact on Person': 'Railimpact op Persoon',
  'Repeated Potential Fatality Incident - Energy': 'Herhaal incident met energie',
  'Repeated Potential Fatality Incident - Zero Energy': 'Herhaal Incident met Zero Energy',
  'Russian (русский)': 'Russisch (русский)',
  'Select / Deselect All': 'Selecteer/Deselecteer Alle',
  'Select a Company Structure': 'Selecteer een Bedrijfsstructuur',
  'Select a GeoArea': 'Selecteer een GeoArea',
  'Select a Site': 'Selecteer een Site',
  'Serbian (Српски)': 'Servisch (Српски)',
  'Site': 'Site',
  'Slope Failure': 'Hellingstoring',
  'Sorry, we couldn\'t load incidents. Please try reloading the page': 'Excuseer, we konden geen incidenten opladen. Tracht de pagina opnieuw te laden',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Excuseer, we konden geen verificaties opladen. Tracht de pagina opnieuw te laden',
  'Spanish (Español)': 'Spaans (Español)',
  'Struck by Falling Object': 'Geslagen door Falling Object',
  'Supervisor Task Based Verification': 'Supervisor Taakgebaseerde Verificatie',
  'Supervisor Verification': 'Supervisor Verificatie',
  'Surface': 'Oppervlakte',
  'US Legal': 'US Wettelijk',
  'US Letter': 'US Brief',
  'Unauthorised': 'Niet toegestaan',
  'Uncontrolled Load During Lifting': 'Ongecontroleerde last tijdens het heffen',
  'Uncontrolled Release of Energy': 'Ongecontroleerde Vrijgave van Energie',
  'Underground Fire': 'Ondergronds vuur',
  'Underground Fire or Explosion': 'Ondergrondse brand of explosie',
  'Underground Hazardous Atmosphere': 'Ondergrondse gevaarlijke atmosfeer',
  'Underground Hoisting': 'Ondergronds hijsen',
  'Underground Inrush': 'Ondergrondse inham',
  'Underground Rock Fall': 'Ondergrondse rotsval',
  'Unplanned Initiation of Explosives': 'Ongeplande initiatie van explosieven',
  'Vehicle Collision (Heavy and Light)': 'Botsing van voertuigen (zwaar en licht)',
  'Vehicle Collision or Rollover': 'Voertuig Botsing of Omtollen',
  'Vehicle Impact on Person': 'Voertuigimpact op Persoon',
  'Vehicle Incident': 'Voertuigincident',
  'Vehicle Pedestrian Interaction - Surface': 'Interactie tussen voetgangers - voertuig',
  'Vehicle Pedestrian Interaction - Underground': 'Voertuig voetgangersinteractie - ondergronds',
  'Verification Date': 'Verificatie Datum',
  'Verification Details': 'Verificatie Details',
  'Verification Type': 'Verificatie Type',
  'Verifier': 'Verificateur',
  'View Incident': 'Incident Bekijken',
  'View Verification': 'Verificatie Bekijken',
  'Warning': 'Waarschuwing',
  'Finnish (Suomalainen)': 'Fins (Suomalainen)',
  'You do not have access to this application. Please contact your site administrator.': 'U heeft geen toegang tot deze applicatie. Contacteer uw sitebeheerder.',
  'You will have to repeat these settings once you hit "Print" below': 'U zal deze instellingen moeten herhalen eens u onderaan op "Afdrukken" drukt',
  'Indonesian (Bahasa Indonesia)': 'Indonesisch (Bahasa Indonesia)'
};
