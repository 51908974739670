
export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        error: action.message,
      };

    case 'SET_COMPANY_LIST':
      return {
        ...state,
        companies: action.companies,
      };

    case 'SET_COMPANY_SITE_IDS':
      return {
        ...state,
        companySites: action.companies,
      };

    case 'SET_GEO_AREA_HIERARCHY':
      const hierarchy = action.hierarchy.sort(alphabetical).map(formatGeoArea);
      const hierarchyBySiteId = hierarchy.reduce(bySiteId, {});
      return {
        ...state,
        geoAreas: {
          loading: false,
          hierarchy,
          hierarchyBySiteId,
        },
      };

    case 'GOTO':
      return {
        ...state,
        goto: action.goto,
        gotoName: action.name,
      };

    case 'SET_TEMP_GOTO_COORDS':
      return {
        ...state,
        gotoCoords: action.coords,
      };

    case 'TOGGLE_GOTO':
      return {
        ...state,
        showGoTo: !state.showGoTo,
      };

    case 'SET_DATE_RANGE':
      return {
        ...state,
        date: {
          to: action.to,
          from: action.from,
        },
      };

    /* Also resets the site level filter */
    case 'SET_COMPANY':
      return {
        ...state,
        visibleGeoAreas: false,
        companyId: action.companyId,
        companyName: action.companyName,
        selected_company_site: '',
        siteLevelsVisible: [1, 2, 3, 4, 5, 6, 7],
        geoAreas: {
          loading: true,
        }
      };

    case 'SET_SPOT_DATA':
      return {
        ...state,
        spotData: action.data[0],
      };

    case 'SET_SPOT_SENSITIVITY':
      return {
        ...state,
        spotSensitivity: action.sensitivity,
      };

    /*
     * We use this seemingly awkward data structure to make rendering
     * more efficient at the component level. Each component has an ID
     * as props, preventing re-rendering if, say, the order of verifications
     * changes - making that component responsible for it's data lookup.
     */
    case 'ADD_VERIFICATIONS':
      const defaultVerifications = {...state.verifications};
      const verifications = action.verifications.reduce((obj, v) => {
        const id = `${v.critical_control}_${v.critical_risk}_${
          v.verification_nid
        }`;

        obj.byId[id] = v;

        const complianceKey =
          v.critical_control_compliance === '1'
            ? 'compliantIds'
            : 'nonCompliantIds';
        obj[complianceKey].push(id);

        return obj;
      }, defaultVerifications);

      return {
        ...state,
        verifications,
      };

    /* See comment above ADD_VERIFICATION - same applies here */
    case 'ADD_INCIDENTS':
      const defaultIncidents = {...state.incidents};
      const incidents = action.incidents.reduce((obj, i) => {
        const {critical_risk, critical_control} = i.attributes;
        const id = `${critical_control}_${critical_risk}_${i.id}`;

        obj.byId[id] = i;

        let tk = ''
        if (i.attributes.incident_energy_exchange === '1') {
          if (i.attributes.incident_repeated === '1') {
          tk = 'pfierIds'
          }
          else {
            tk = 'pfieIds'
          }
        }
        else {
          if (i.attributes.incident_repeated === '1') {
            tk = 'pfizerIds'
          }
          else {
            tk = 'pfizeIds'
          }
        }

        if (['pfieIds', 'pfierIds'].includes(tk) && 'fatality' in i.attributes && i.attributes.fatality === 'yes') {
          let splitted_tk = tk.split('Ids')
          tk = splitted_tk[0] + 'fIds';
        }

        const typeKey = tk
        obj[typeKey].push(id);

        return obj;
      }, defaultIncidents);

      return {
        ...state,
        incidents,
      };

    case 'CLEAR_MAP_DATA':
      return {
        ...state,
        incidents: {
          byId: {},
          pfizeIds: [],
          pfizerIds: [],
          pfieIds: [],
          pfierIds: [],
          pfiefIds: [],
          pfierfIds: [],
        },
        verifications: {
          byId: {},
          compliantIds: [],
          nonCompliantIds: [],
        },
      };

    case 'SET_RISKS':
      return {
        ...state,
        risks: action.criticalRisks,
        criticalRisksVisible: Object.keys(action.criticalRisks).map(k => action.criticalRisks[k]),
      };

    case 'SET_SELECTED_COMPANY_SITE':
      return {
        ...state,
        selected_company_site: action.selected_company_site,
      };

    case 'SET_VERIFICATION_TYPES_VISIBLE':
      return {
        ...state,
        verificationTypesVisible: action.verificationTypesVisible,
      };

    case 'SET_CRITICAL_RISKS_VISIBLE':
      return {
        ...state,
        criticalRisksVisible: action.criticalRisksVisible,
      };

    case 'SET_VISIBLE_SITE_LEVELS':
      return {
        ...state,
        siteLevelsVisible: action.levels,
      };

    /* Allows a loading value for each request ID. This means you can show
     * an indicator if "x" is loading
     */
    case 'API_CALL_STARTED':
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.id]: true,
        },
      };

    case 'API_CALL_FINISHED':
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.id]: false,
        },
      };

    /*
     * This sligtly cumbersome way of modifying the array is to ensure
     * immutability. On the true side of the ternary, we return a new
     * array without the given group in it - basically "deleting" it. And
     * on second condition, we create a new array with all the old values
     * plus the new provided value, essentially "pushing" the value.
     */
    case 'TOGGLE_GROUP_VISIBILITY':
      const groupsVisible = state.groupsVisible.includes(action.group)
        ? state.groupsVisible.filter(g => g !== action.group)
        : [...state.groupsVisible, action.group];

      return {
        ...state,
        groupsVisible,
      };

    case 'SET_MAP_POSITION':
      return {
        ...state,
        initialZoom: action.zoom,
        initialCenter: action.center,
      };

    case 'SET_POPUP':
      return {
        ...state,
        popup: action.id,
      };

    case 'SET_GEO_AREA_VISIBILITY':
      return {
        ...state,
        visibleGeoAreas: action.visibleGeoAreas,
      };

    case 'MAP_READY':
      return {
        ...state,
        mapReady: true,
      };

    default:
      return state;
  }
};

function bySiteId(obj, geoArea) {
  return {
    ...obj,
    [geoArea.siteId]: geoArea,
  };
}

function alphabetical(a, b) {
  return a.name > b.name ? 1 : -1;
}

function formatGeoArea(geoArea) {
  const geojson = JSON.parse(geoArea.geoJson);
  const coords = geojson.geometry.coordinates[0].map(c => [c[1], c[0]]);

  return {
    ...geoArea,
    coords,
    siteId: geoArea.site_id,
    children: geoArea.children.sort(alphabetical).map(formatGeoArea),
  };
}
