import {connect} from 'react-redux';

import Component from '../Components/Header';

const mapStateToProps = state => {
  return {
    isMobile: state.ui.isMobile,
    showHamburger: state.ui.navMenuEnabled,
    showNavMenu: state.ui.showNavMenu,
    username: state.auth.username,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleNavMenu() {
      dispatch({type: 'UI_TOGGLE_NAV_MENU'});
    },
    logout() {
      dispatch({type: 'AUTH_LOGOUT'});
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
