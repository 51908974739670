export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_PRINT_ORIENTATION':
      return {
        ...state,
        orientation: action.orientation,
      };

    case 'SET_PRINT_SIZE':
      return {
        ...state,
        length: action.length,
      };

    case 'SET_PRINT_VISIBILITY':
      return {
        ...state,
        isVisible: action.isVisible,
      };

    case 'PRINT':
      return {
        ...state,
        isPrinting: true,
      };

    case 'SET_PRINT_TYPE':
      return {
        ...state,
        printType: action.printType,
      };

    default:
      return state;
  }
};
