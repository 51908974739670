import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

/* IE11 Polyfills */
import 'core-js';
import 'react-app-polyfill/ie11';

import './index.css';
import App from './App';
import store from './store';

/* <Provider /> initialises Redux. Future React concurrent changes will go hre */
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,

  document.getElementById('root'),
);
