module.exports = {
  "A3": "A3",
  "A4": "A4",
  "A5": "A5",
  "All Sites": "Kaikki toimipaikat",
  "Assisting Verifiers": "Apuvarmentajat",
  "COVID-19 (Coronavirus)": "COVID-19 (koronavirus)",
  "Cannot load GeoAreas": "Geoalueita ei voida ladata",
  "Cannot retrieve Critical Risks": "Kriittisiä riskejä ei voida noutaa",
  "Cannot retrieve Go To list": "Go To -luetteloa ei voida noutaa",
  "Cannot retrieve company list.": "Yritysluetteloa ei voida noutaa.",
  "Cannot retrieve map overlays": "Kartan peittokuvia ei voida noutaa",
  "Chinese, Simplified": "Kiina, yksinkertainen",
  "Chinese, Simplified (简化字)": "Kiina, yksinkertainen (简化字)",
  "Coach": "Valmentaja",
  "Company Structure": "Yritysrakenne",
  "Compliant Verification": "Vaatimustenmukaisuuden varmentaminen",
  "Compliant Verification Group": "Vaatimustenmukaisuuden varnentamisryhmä",
  "Confined Spaces": "Ahtaat tilat",
  "Contact with Electricity": "Sähkökosketus",
  "Contact with Molten Material": "Kosketus sulaan aineeseen",
  "Coordinates": "Koordinaatit",
  "Critical Control": "Kriittinen kontrolli",
  "Critical Control Status": "Kriittisen kontrollin tila",
  "Critical Risk": "Kriittinen riski",
  "Date Range": "Päivämääräalue",
  "Drowning": "Hukkuminen",
  "Dutch (Nederlands)": "Hollanti (Nederlands)",
  "English (English)": "Englanti (English)",
  "Entanglement and Crushing": "Sotkeutuminen ja puristuminen",
  "Entanglement in Rotating Equipment": "Sotkeutuminen pyörivään laitteeseen",
  "Error": "Virhe",
  "Event in a Confined Space": "Vaaratilanne ahtaassa tilassa",
  "Explosives Handling": "Räjähteiden käsittely",
  "Exposure to Chemicals that Burn": "Altistuminen palaville kemikaaleille",
  "Exposure to Hazardous Substances": "Altistuminen vaarallisille aineille",
  "Exposure to Hazardous Substances (Chronic)": "Altistuminen vaarallisille aineille (krooninen)",
  "Exposure to Thermal Extremes": "Altistuminen äärilämpötiloille",
  "Fall from Height": "Korkealta putoaminen",
  "Fall of Ground - Surface": "Maan sortuma - pinta",
  "Fall of Ground - Underground": "Maan sortuma - maanalainen",
  "Falling Objects": "Putoavat esineet",
  "Filter by Company Structure": "Suodatus yritysrakenteen mukaan",
  "Filter by Critical Risk": "Suodatus kriittisen riskin mukaan",
  "Filter by Date": "Suodatus varmennuspäivän mukaan",
  "Filter by GeoArea": "Suodatus geoalueen mukaan",
  "Filter by Site": "Suodatus toimipaikan mukaan",
  "Filter by Site Level": "Suodatus toimipaikkatason mukaan",
  "Filter by Verification Type": "Suodatus varmennustyypin mukaan",
  "French (Français)": "Ranska (Français)",
  "GeoArea": "Geoalue",
  "German (Deutsch)": "Saksa (Deutsch)",
  "Go": "Siirry",
  "Goto": "Siirry",
  "Goto Coordinates": "Siirry koordinaatteihin",
  "Goto GeoArea": "Siirry geoalueelle",
  "Grizzly Bear Interactions": "Interaktiot harmaakarhun kanssa",
  "Hazardous Material": "Vaarallinen aine",
  "Heavy Vehicle Event - Rollover, Over Edge": "Raskaaseen ajoneuvoon liittyvä vaaratilanne - kaatuminen, yli reunan",
  "Icelandic (Íslensku)": "Islanti (Íslensku)",
  "Incident Date": "Vaaratilanteen päivämäärä",
  "Incident Details": "Vaaratilanteen tiedot",
  "Incident During Tire Handling": "Vaaratilanne renkaita käsiteltäessä",
  "Italian (Italiano)": "Italia (Italiano)",
  "Landscape": "Vaakasuunta",
  "Language": "Kieli",
  "Legend": "Merkintä",
  "Level": "Taso",
  "Lifting Operations": "Nostotoiminnot",
  "Loading Map Data": "Lataus",
  "Location": "Sijainti",
  "Logout": "Uloskirjautuminen",
  "Malaria": "Malaria",
  "Manager Verification": "Johtajan varmennus",
  "Message": "Sanoma",
  "Mongolian (Монгол хэл)": "Mongolia (Монгол хэл)",
  "Mooring Operations": "Kiinnitystoiminnot",
  "No incident results found. Please try changing the date range.": "Yhtään vaaratilannetulosta ei löytynyt. Kokeile päivämääräalueen vaihtoa.",
  "No verification results found. Please try changing the date range.": "Yhtään varmennustulosta ei löytynyt. Kokeile päivämääräalueen vaihtoa.",
  "Non Compliant Verification": "Vaatimustenvastaisuuden varmentaminen",
  "Non Compliant Verification Group": "Vaatimustenvastaisuuden varmentamisryhmä",
  "Norwegian (Norsk)": "Norja (Norsk)",
  "Operator Task Based Verification": "Operaattorin tehtäväpohjainen varmennus",
  "Operator Verification": "Operaattorin varmennus",
  "Orientation": "Suunta",
  "Paper Size": "Paperin koko",
  "Physical Location": "Fyysinen sijainti",
  "Please note: Changing these options prepares the map for printing.": "Huomaa: Näiden valintojen vaihtaminen valmistelee kartan tulostettavaksi.",
  "Portrait": "Pystysuunta",
  "Portuguese (Português)": "Portugali (Português, Brasil)",
  "Potential Fatality Incident - Energy": "Energiaan liittyvä vaaratilanne",
  "Potential Fatality Incident - Zero Energy": "Nollaenergiaan liittyvä vaaratilanne",
  "Print": "Tulosta",
  "Print Data": "Tulostuspäivä",
  "Print Map": "Tulosta tämä sivu",
  "Print Options": "Tulostusasetukset",
  "Print Preview": "Tulostuksen esikatselu",
  "Rail Collision": "Törmäys raiteilla",
  "Rail Impact on Person": "Henkilön iskeytyminen raiteisiin",
  "Repeated Potential Fatality Incident - Energy": "Toistuva energiaan liittyvä vaaratilanne",
  "Repeated Potential Fatality Incident - Zero Energy": "Toistuva nollaenergiaan liittyvä vaaratilanne",
  "Russian (русский)": "Venäjä (русский)",
  "Select / Deselect All": "Valitse / poista kaikki valinnat",
  "Select a Company Structure": "Valitse yritysrakenne",
  "Select a GeoArea": "Valitse geoalue",
  "Select a Site": "Valitse toimipaikka",
  "Serbian (Српски)": "Serbia (Српски)",
  "Site": "Toimipaikka",
  "Slope Failure": "Luiskan romahtaminen",
  "Sorry, we couldn't load incidents. Please try reloading the page": "Pahoittelemme, emme pystyneet lataamaan vaaratilanteita. Yritä ladata sivu uudelleen",
  "Sorry, we couldn't load verifications. Please try reloading the page": "Pahoittelemme, emme pystyneet lataamaan varmenneuksia. Yritä ladata sivu uudelleen",
  "Spanish (Español)": "Espanja (Español)",
  "Struck by Falling Object": "Putoavan esineen aiheuttama isku",
  "Supervisor Task Based Verification": "Esimiehen tehtäväpohjainen varmennus",
  "Supervisor Verification": "Esimiehen varmennus",
  "Surface": "Pinta",
  "US Legal": "US Legal",
  "US Letter": "US Letter",
  "Unauthorised": "Valtuuttamaton",
  "Uncontrolled Load During Lifting": "Hallitsematon kuorma nostamisen aikana",
  "Uncontrolled Release of Energy": "Energian hallitsematon vapautuminen",
  "Underground Fire": "Maanalainen tulipalo",
  "Underground Fire or Explosion": "Maanalainen tulipalo tai räjähdys",
  "Underground Hazardous Atmosphere": "Maanalainen vaarallinen ilmaseos",
  "Underground Hoisting": "Maanalaiset nostotyöt",
  "Underground Inrush": "Maanalainen kytkentävirta",
  "Underground Rock Fall": "Maanalainen kivisortuma",
  "Unplanned Initiation of Explosives": "Suunnittelematon räjähteiden sytyttäminen",
  "Vehicle Collision (Heavy and Light)": "Ajoneuvojen törmäys (raskas ja kevyt)",
  "Vehicle Collision or Rollover": "Ajoneuvojen törmäys tai kaatuminen",
  "Vehicle Impact on Person": "Ajoneuvon iskeytyminen henkilöön",
  "Vehicle Incident": "Ajoneuvoon liittyvä vaaratilanne",
  "Vehicle Pedestrian Interaction - Surface": "Ajoneuvon ja jalankulkijan välinen interaktio - pinta",
  "Vehicle Pedestrian Interaction - Underground": "Ajoneuvon ja jalankulkijan välinen interaktio - maanalainen",
  "Verification Date": "Varmennuspäivä",
  "Verification Details": "Varmennustiedot",
  "Verification Type": "Varmennustyyppi",
  "Verifier": "Varmentaja",
  "View Incident": "Tarkastele vaaratilannetta",
  "View Verification": "Tarkastele varmennusta",
  "Warning": "Varoitus",
  "You do not have access to this application. Please contact your site administrator.": "Sinulla ei ole pääsyä tähän sovellukseen. Ota yhteyttä järjestelmänvalvojaan.",
  "You will have to repeat these settings once you hit \"Print\" below": "Sinun on toistettava nämä asetukset, kun painat \"Tulosta\" alla",
  'Indonesian (Bahasa Indonesia)': 'Indonesialainen (Bahasa Indonesia)'
};
