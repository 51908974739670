/*
 * WARNING: This code deals with obscure issues at the intersection of
 * Map rendering with Google Maps and IE printing. Modify at your peril
 *
 * This is basically to trigger a window resize event when the print size is
 * changed by the user, this forces the Google Maps library to re-evaluate it's
 * size, preventing empty grey tiles on print.
 */
export default store => {
  return next => action => {
    next(action);

    if (
      action.type === 'SET_PRINT_ORIENTATION' ||
      action.type === 'SET_PRINT_SIZE' ||
      action.type === 'SET_PRINT_VISIBILITY' ||
      action.type === 'AUTH_SET'
    ) {
      setTimeout(() => {
        if (
          navigator.userAgent.indexOf('MSIE') !== -1 ||
          navigator.appVersion.indexOf('Trident/') > 0
        ) {
          var evt = document.createEvent('UIEvents');
          evt.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(evt);
        } else {
          window.dispatchEvent(new Event('resize'));
        }
      }, 200);
    }
  };
};
