module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Alle nettsteder',
  'Assisting Verifiers': 'Assistere Verifiers',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'Kan ikke laste GeoAreas',
  'Cannot retrieve Critical Risks': 'Kan ikke hente kritiske risikoer',
  'Cannot retrieve Go To list': 'Kan ikke hente Gå Til-listen.',
  'Cannot retrieve company list.': 'Kan ikke hente firmaliste.',
  'Cannot retrieve map overlays': 'Kan ikke hente kartoverlegg',
  'Chinese, Simplified': 'Kinesisk, simplifisert',
  'Chinese, Simplified (简化字)': 'Kinesisk (简化字)',
  'Coach': 'Trener',
  'Company Structure': 'Selskapsstruktur',
  'Compliant Verification': 'Overensstemmende Bekreftelse',
  'Compliant Verification Group': 'Overensstemmende Bekreftelse Gruppe',
  'Confined Spaces': 'Begrensede rom',
  'Contact with Electricity': 'Kontakt med elektrisitet',
  'Contact with Molten Material': 'Kontakt med smeltet materiale',
  'Coordinates': 'koordinater',
  'Critical Control': 'Kritisk Kontroll',
  'Critical Control Status': 'Kritisk Kontrollstatus',
  'Critical Risk': 'Kritisk Risiko',
  'Date Range': 'Datointervall',
  'Drowning': 'Drukning',
  'Dutch (Nederlands)': 'nederlandsk (Nederlands)',
  'English (English)': 'Engelsk (English)',
  'Entanglement and Crushing': 'Forviklinger og knusing',
  'Entanglement in Rotating Equipment': 'Forviklinger i roterende utstyr',
  'Error': 'Feil',
  'Event in a Confined Space': 'Hendelse i et avgrenset rom',
  'Explosives Handling': 'Eksplosiver Håndtering',
  'Exposure to Chemicals that Burn': 'Eksponering til kjemikalier som gir brannskader',
  'Exposure to Hazardous Substances': 'Eksponering for farlige stoffer',
  'Exposure to Hazardous Substances (Chronic)': 'Eksponering for farlige stoffer (kronisk',
  'Exposure to Thermal Extremes': 'Eksponering til ekstreme termiske forhold',
  'Fall from Height': 'Fall fra høyden',
  'Fall of Ground - Surface': 'Falle av bakken - flate',
  'Fall of Ground - Underground': 'Falle av bakken - undergrunnen',
  'Falling Objects': 'Fallende objekter',
  'Filter by Company Structure': 'Filtrer etter selskapsstruktur',
  'Filter by Critical Risk': 'Filtrer etter kritisk risiko',
  'Filter by Date': 'Filtrer etter verifiseringsdato',
  'Filter by GeoArea': 'Filtrer etter Geo-område',
  'Filter by Site': 'Filtrer etter nettsted',
  'Filter by Site Level': 'Filtrer etter nettstedsnivå',
  'Filter by Verification Type': 'Filtrer etter verifiseringstype',
  'French (Français)': 'Fransk (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Tysk (Deutsch)',
  'Go': 'Gå',
  'Goto': 'Gå til',
  'Goto Coordinates': 'Gå til koordinater',
  'Goto GeoArea': 'Gå til GeoArea',
  'Grizzly Bear Interactions': 'Gå til GeoArea',
  'Hazardous Material': 'Farlig materiale',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Tunge kjøretøyarrangement - Rull over, over kant',
  'Icelandic (Íslensku)': 'islandsk (Íslensku)',
  'Incident Date': 'Hendelsesdato',
  'Incident Details': 'Hendelsesdetaljer',
  'Incident During Tire Handling': 'Hendelse under dekkhåndtering',
  'Italian (Italiano)': 'Italiensk (Italiano)',
  'Landscape': 'Landskap',
  'Language': 'Språk',
  'Legend': 'Legende',
  'Level': 'Nivå',
  'Lifting Operations': 'Løfteoperasjoner',
  'Loading Map Data': 'Laster inn kartdata',
  'Location': 'Plassering',
  'Logout': 'Logg ut',
  'Malaria': 'malaria',
  'Manager Verification': 'Manager-bekreftelse',
  'Message': 'Budskap',
  'Mongolian (Монгол хэл)': 'Mongolsk (Монгол хэл)',
  'Mooring Operations': 'Fortøyningsoperasjoner',
  'No incident results found. Please try changing the date range.': 'Fant ingen hendelsesresultater. Prøv å endre datointervallet.',
  'No verification results found. Please try changing the date range.': 'Ingen bekreftelsesresultater funnet. Prøv å endre datointervallet.',
  'Non Compliant Verification': 'Bekreftelse som ikke er i samsvar',
  'Non Compliant Verification Group': 'Ikke-samsvarende verifiseringsgruppe',
  'Norwegian (Norsk)': 'norsk (Norsk)',
  'Operator Task Based Verification': 'Operatøroppgavebasert bekreftelse',
  'Operator Verification': 'Operatørverifisering',
  'Orientation': 'orientering',
  'Paper Size': 'Papir størrelse',
  'Physical Location': 'Fysisk Beliggenhet',
  'Please note: Changing these options prepares the map for printing.': 'Merk: Endring av disse alternativene forbereder kartet til utskrift.',
  'Portrait': 'Portrett',
  'Portuguese (Português)': 'Portugisisk (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Potensiell dødelighet - energi',
  'Potential Fatality Incident - Zero Energy': 'Potensiell dødelighet - null energi',
  'Print': 'Skrive ut',
  'Print Data': 'Skriv ut data',
  'Print Map': 'Skriv ut kart',
  'Print Options': 'Utskriftsalternativer',
  'Print Preview': 'Forhåndsvisning av utskrift',
  'Rail Collision': 'Togkollisjon',
  'Rail Impact on Person': 'Jernbaneeffekt på person',
  'Repeated Potential Fatality Incident - Energy': 'Gjenta hendelsen med energi',
  'Repeated Potential Fatality Incident - Zero Energy': 'Gjenta hendelsen med null energi',
  'Russian (русский)': 'Russisk (русский)',
  'Select / Deselect All': 'Velg / fjern merket for alle',
  'Select a Company Structure': 'Velg en selskapsstruktur',
  'Select a GeoArea': 'Velg en GeoArea',
  'Select a Site': 'Velg et nettsted',
  'Serbian (Српски)': 'Serbisk (Српски)',
  'Site': 'Nettstedet',
  'Slope Failure': 'Skråningsbrudd',
  'Sorry, we couldn\'t load incidents. Please try reloading the page': 'Beklager, vi kunne ikke laste inn hendelser. Prøv å laste siden på nytt.',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Beklager, vi kunne ikke laste inn bekreftelser. Prøv å laste siden på nytt.',
  'Spanish (Español)': 'Spansk (Español)',
  'Struck by Falling Object': 'Truffet av fallende gjenstand',
  'Supervisor Task Based Verification': 'Veileders oppgavebasert bekreftelse',
  'Supervisor Verification': 'Verifisering av veileder',
  'Surface': 'Flate',
  'US Legal': 'US Legal',
  'US Letter': 'US Letter',
  'Unauthorised': 'Uautorisert',
  'Uncontrolled Load During Lifting': 'Ukontrollert belastning under løfting',
  'Uncontrolled Release of Energy': 'Ukontrollert frigjøring av energi',
  'Underground Fire': 'Brann under jorden',
  'Underground Fire or Explosion': 'Underjordisk brann eller eksplosjon',
  'Underground Hazardous Atmosphere': 'Farlig atmosfære under jorden',
  'Underground Hoisting': 'Heising under jorden',
  'Underground Inrush': 'Vanninntrengning under jorden',
  'Underground Rock Fall': 'Steinskred under jorden',
  'Unplanned Initiation of Explosives': 'Ikke-planlagt startimpuls for sprengstoff',
  'Vehicle Collision (Heavy and Light)': 'Kjøretøy kollisjon (tung og lett)',
  'Vehicle Collision or Rollover': 'Kollisjon eller velt med mobilt utstyr',
  'Vehicle Impact on Person': 'Kontakt mellom mobilt utstyr og person',
  'Vehicle Incident': 'Kjøretøyhendelse',
  'Vehicle Pedestrian Interaction - Surface': 'Bilens fotgjengersamhandling - overflate',
  'Vehicle Pedestrian Interaction - Underground': 'Bilens fotgjengersamhandling - undergrunnen',
  'Verification Date': 'Verifiseringsdato',
  'Verification Details': 'Bekreftelsesdetaljer',
  'Verification Type': 'Verifikasjonstype',
  'Verifier': 'Verifier',
  'View Incident': 'Vis Hendelse',
  'View Verification': 'Vis Bekreftelse',
  'Warning': 'Advarsel',
  'Finnish (Suomalainen)': 'Finsk (Suomalainen)',
  'You do not have access to this application. Please contact your site administrator.': 'Du har ikke tilgang til denne applikasjonen. Kontakt nettstedadministratoren din.',
  'You will have to repeat these settings once you hit "Print" below': 'Du må gjenta disse innstillingene når du trykker "Skriv ut" nedenfor',
  'Indonesian (Bahasa Indonesia)': 'Indonesisk (Bahasa Indonesia)'
};
