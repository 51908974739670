/*
 * We are using the amazon-cognito-identity-js package instead of the full
 * AWS Amplify library as it is much lighter, saving us about 300kb in bundle
 * size. This is because AWS Amplify library is not setup to allow
 * "tree shaking". They are working on this so at some point in the future
 * it could be worth switching back to AWS Amplify. This library though is
 * what AWS Amplify uses underneath
 */
import {CognitoUserPool, CookieStorage} from 'amazon-cognito-identity-js';
import Config from '../Config';

const poolConfig = {
  ClientId: Config.Auth.userPoolWebClientId,
  UserPoolId: Config.Auth.userPoolId,
  Storage: new CookieStorage(Config.Auth.cookieStorage),
};

/*
 * This code is fired instantly, before even the Redux code has initted, since
 * the cookie will be there or not, this is just to make it quicker in the case
 * of no user session
 */
const pool = new CognitoUserPool(poolConfig);
const user = pool.getCurrentUser();

const isMini = process.env.REACT_APP_TYPE === 'mini';
let error = false;

if (!user && !isMini) redirect();
else if (!user && isMini) error = 'NO_SESSION';

function redirect() {
  window.location.href = Config.forwood_id_url;
}

export default store => next => action => {
  next(action);

  if (action.type === 'MINI_MAP_INIT' && !user) {
    return store.dispatch({type: 'MINI_MAP_ERROR', error: 'NO_SESSION'});
  }

  const triggerActions = ['INIT', 'AUTH_REFRESH', 'MINI_MAP_INIT'];
  if (triggerActions.indexOf(action.type) > -1 && !!user) {
    user.getSession((err, result) => {
      if (err && !isMini) return redirect();
      if (!result && isMini) {
        return store.dispatch({type: 'MINI_MAP_ERROR', error: 'NO_SESSION'});
      }

      const payload = result.idToken.payload;
      const groups = payload['cognito:groups'];
      const locale = payload.locale;

      /*
       * Uncommenting LANGUAGE_SET line will allow users language to be set
       * within Forwood ID. Note if doing this you will need to remove the
       * LANGUAGE_SET action from middleware/localstorage or they will clash
       */
      if (locale) {
        // store.dispatch({ type: "LANGUAGE_SET", locale });
      }
      /* User must have MapReportAccess role in Forwood ID to view app */
      if (!groups || groups.indexOf('MapReportAccess') === -1) {
        if (!isMini) {
          return store.dispatch({
            type: 'SET_UNAUTHORISED',
            username: payload['cognito:username'],
          });
        }
      }

      const auth = {
        jwtToken: result.idToken.jwtToken,
        username: payload.email,
        ...result.idToken.payload,
      };

      setTimeout(() => store.dispatch({type: 'AUTH_REFRESH'}), 300000);

      const type =
        action.type === 'INIT'
          ? 'AUTH_SET'
          : action.type === 'MINI_MAP_INIT'
          ? 'MINI_AUTH_SET'
          : 'AUTH_UPDATE';

      store.dispatch({type, ...auth});
    });
  } else if (action.type === 'AUTH_LOGOUT') {
    const user = pool.getCurrentUser();
    store.dispatch({type: 'AUTH_CLEAR'});
    user.signOut();
    window.location.reload();
  }

  function handleAuth(err, result) {}
};
