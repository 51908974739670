module.exports = {
  "A3": "A3",
  "A4": "A4",
  "A5": "A5",
  "All Sites": "Tüm Siteler",
  "Assisting Verifiers": "Doğrulayanlara Yardımcı Olma",
  "COVID-19 (Coronavirus)": "COVID-19 (Koronavirüs)",
  "Cannot load GeoAreas": "Geoareas yükleyemez",
  "Cannot retrieve Critical Risks": "Kritik riskleri geri alamıyorum",
  "Cannot retrieve Go To list": "Git listesi alınamıyor",
  "Cannot retrieve company list.": "Şirket listesi alınamıyor.",
  "Cannot retrieve map overlays": "Harita yer paylaşımları alınamıyor",
  "Chinese, Simplified": "Çince, basitleştirilmiş",
  "Chinese, Simplified (简化字)": "Çince, basitleştirilmiş (简化字)",
  "Coach": "Koç",
  "Company Structure": "Şirket Yapısı",
  "Compliant Verification": "Uyumlu Doğrulama",
  "Compliant Verification Group": "Uyumlu Doğrulama Grubu",
  "Confined Spaces": "Kapalı alanlar",
  "Contact with Electricity": "Elektrikle Temas",
  "Contact with Molten Material": "Erimiş malzeme ile temas",
  "Coordinates": "Koordinatlar",
  "Critical Control": "Kritik Kontrol",
  "Critical Control Status": "Kritik Kontrol Durumu",
  "Critical Risk": "Kritik Risk",
  "Date Range": "Tarih Aralığı",
  "Drowning": "Boğulma",
  "Dutch (Nederlands)": "Hollandalı (Nederlands)",
  "English (English)": "İngilizce (İngilizce)",
  "Entanglement and Crushing": "Dolaşma ve ezme",
  "Entanglement in Rotating Equipment": "Dönen Ekipmanlara Dolaşma",
  "Error": "Hata",
  "Event in a Confined Space": "Kapalı Alan Çalışması",
  "Explosives Handling": "Patlayıcıları Elleçleme",
  "Exposure to Chemicals that Burn": "Kimyasal Yanığına Maruz Kalma",
  "Exposure to Hazardous Substances": "Tehlikeli Maddelere Maruz Kalma",
  "Exposure to Hazardous Substances (Chronic)": "Tehlikeli maddelere maruz kalma (kronik)",
  "Exposure to Thermal Extremes": "Aşırı Sıcağa maruz kalma",
  "Fall from Height": "Yüksekten Düşme",
  "Fall of Ground - Surface": " Düşme – Açık Ocak",
  "Fall of Ground - Underground": "Düşme - Yeraltı",
  "Falling Objects": "Düşen nesneler",
  "Filter by Company Structure": "Şirket Yapısına Göre Filtre",
  "Filter by Critical Risk": "Kritik Riske Göre Filtreleme",
  "Filter by Date": "Tarihe Göre Filtreleme",
  "Filter by GeoArea": "Geoarea tarafından Filtre",
  "Filter by Site": "Siteye Göre Filtre",
  "Filter by Site Level": "Site seviyesine göre filtre",
  "Filter by Verification Type": "Doğrulama Türüne Göre Filtre",
  "French (Français)": "Fransızca (Français)",
  "GeoArea": "coğrafi alan",
  "German (Deutsch)": "Almanca (Deutsch)",
  "Go": "Git ",
  "Goto": "Git",
  "Goto Coordinates": "Koordinatlara git",
  "Goto GeoArea": "GeoArea'ya Git",
  "Grizzly Bear Interactions": "Boz Ayı Etkileşimleri",
  "Hazardous Material": "Tehlikeli Malzeme",
  "Heavy Vehicle Event - Rollover, Over Edge": "Ağır Vasıta Etkinliği - Devrilme, Kenardan Geçme",
  "Icelandic (Íslensku)": "İzlandaca (Íslensku)",
  "Incident Date": "Olay Tarihi",
  "Incident Details": "Olay Ayrıntıları",
  "Incident During Tire Handling": "Lastik Kullanımı Sırasında Olay",
  "Italian (Italiano)": "İtalyan (İtalyan)",
  "Landscape": "Peyzaj",
  "Language": "Dil",
  "Legend": "Efsane",
  "Level": "Seviye",
  "Lifting Operations": "Kaldırma işlemleri",
  "Loading Map Data": "Harita Verilerini Yükleme",
  "Location": "Konum",
  "Logout": "Oturum Açma",
  "Malaria": "Sıtma",
  "Manager Verification": "Yönetici Doğrulaması",
  "Message": "Mesaj",
  "Mongolian (Монгол хэл)": "Moğol (мнгол хэл)",
  "Mooring Operations": "Baglama Operasyonları",
  "No incident results found. Please try changing the date range.": "Olay sonucu bulunamadı. Lütfen tarih aralığını değiştirmeyi deneyin.",
  "No verification results found. Please try changing the date range.": "Doğrulama sonucu bulunamadı. Lütfen tarih aralığını değiştirmeyi deneyin.",
  "Non Compliant Verification": "Uyumlu olmayan doğrulama",
  "Non Compliant Verification Group": "Uyumlu olmayan doğrulama grubu",
  "Norwegian (Norsk)": "Norveç (Norsk)",
  "Operator Task Based Verification": "Operatör Görev Tabanlı Doğrulama",
  "Operator Verification": "Operatör Doğrulama",
  "Orientation": "Oryantasyon",
  "Paper Size": "Kağıt Boyutu",
  "Physical Location": "Fiziksel Konum",
  "Please note: Changing these options prepares the map for printing.": "Lütfen dikkat: Bu seçenekleri değiştirmek haritayı yazdırma için hazırlar.",
  "Portrait": "Portre",
  "Portuguese (Português)": "Portekizce (Português, Brasil)",
  "Potential Fatality Incident - Energy": "Potansiyel ölümle sonuçlanacak olay - Enerji",
  "Potential Fatality Incident - Zero Energy": "Potansiyel ölümle sonuçlanacak olay - Sıfır Enerji",
  "Print": "Yazdır",
  "Print Data": "Yazdırma Verileri",
  "Print Map": "Yazdır haritası",
  "Print Options": "Yazdırma Seçenekleri",
  "Print Preview": "Baskı Önizleme",
  "Rail Collision": "Demiryolu Çarpışmas",
  "Rail Impact on Person": "Kişi Üzerine Demiryolu Etkisi",
  "Repeated Potential Fatality Incident - Energy": "Tekrarlanan Potansiyel ölümle sonuçlanacak olay - Enerji",
  "Repeated Potential Fatality Incident - Zero Energy": "Tekrarlanan potansiyel ölümle sonuçlanacak olay - Sıfır Enerji",
  "Russian (русский)": "Rusça (русский)",
  "Select / Deselect All": "Tümünü seçin / seçin",
  "Select a Company Structure": "Bir Şirket Yapısı Seç",
  "Select a GeoArea": "Bir Geoarea Seç",
  "Select a Site": "Bir Site Seç",
  "Serbian (Српски)": "Sırpça (Српски)",
  "Site": "Alan",
  "Slope Failure": "Şev kayması",
  "Sorry, we couldn't load incidents. Please try reloading the page": "Üzgünüm, olayları yükleyemedik. Lütfen sayfayı yeniden yüklemeyi deneyin",
  "Sorry, we couldn't load verifications. Please try reloading the page": "Üzgünüm, doğrulamaları yükleyemedik. Lütfen sayfayı yenilemeyi deneyin",
  "Spanish (Español)": "İspanyolca (Español)",
  "Struck by Falling Object": "Düşen nesne tarafından vuruldu",
  "Supervisor Task Based Verification": "Süpervizör Görev Tabanlı Doğrulama",
  "Supervisor Verification": "Süpervizör Doğrulaması",
  "Surface": "Yüzey",
  "US Legal": "ABD Yasası",
  "US Letter": "ABD Mektubu",
  "Unauthorised": "Yetkisiz",
  "Uncontrolled Load During Lifting": "Kaldırma sırasında kontrolsüz yük",
  "Uncontrolled Release of Energy": "Kontrolsüz Enerjinin Serbest Bırakılması",
  "Underground Fire": "Yeraltı Yangını",
  "Underground Fire or Explosion": "Yeraltı Yangın veya Patlama",
  "Underground Hazardous Atmosphere": "Yeraltı Tehlikeli Atmosfer",
  "Underground Hoisting": "Yeraltı Kaldırma",
  "Underground Inrush": "Yeraltı Inrush",
  "Underground Rock Fall": "Yeraltı Kaya (Kavlak)Düşmesi",
  "Unplanned Initiation of Explosives": "Patlayıcıların Yanlışlıkla Ateşlenmesi",
  "Vehicle Collision (Heavy and Light)": "Araç Çarpışması (Ağır ve Hafif)",
  "Vehicle Collision or Rollover": "Araç Çarpışması veya Devrilmesi",
  "Vehicle Impact on Person": "Aracın Kişi Üzerine Etkisi",
  "Vehicle Incident": "Araç olayı",
  "Vehicle Pedestrian Interaction - Surface": "Araç yaya etkileşimi - yüzey",
  "Vehicle Pedestrian Interaction - Underground": "Araç yaya etkileşimi - yeraltı",
  "Verification Date": "Doğrulama tarihi",
  "Verification Details": "Doğrulama Ayrıntıları",
  "Verification Type": "Doğrulama Türü",
  "Verifier": "Doğrulayıcı",
  "View Incident": "Olayı Görüntüle",
  "View Verification": "Doğrulamayı Görüntüle",
  "Warning": "Uyarı",
  "Finnish (Suomalainen)": "Fince (Suomalainen)",
  "Indonesian (Bahasa Indonesia)": "Endonezce (Bahasa Endonezya)",
  "You do not have access to this application. Please contact your site administrator.": "Bu uygulamaya erişiminiz yok. Lütfen site yöneticinizle iletişime geçin..",
  "You will have to repeat these settings once you hit \"Print\" below": "Aşağıda \"Yazdır\" düğmesine bastığınızda bu ayarları tekrarlamanız gerekecek"
}