import {connect} from 'react-redux';

import Component from '../Components/FilterMenu';

const mapStateToProps = state => {
  return {
    isMobile: state.ui.isMobile,
    isUnauthorised: state.auth.unauthorised,
    showFilterMenu: state.ui.showFilterMenu,
    showCompanySiteFilter: state.map.company_sites.length > 0,
    companySiteFilterLoading: state.map.loading.company_sites,
    /* Ensures doesnt load if user needs to select a co structure */
    isReady: !!state.map.companyId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleFilterMenu() {
      dispatch({type: 'UI_TOGGLE_FILTER_MENU'});
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
