module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Todas las Areas',
  'Assisting Verifiers': 'Verificadores Acompañantes',
  'COVID-19 (Coronavirus)': 'COVID-19 (coronavirus)',
  'Cannot load GeoAreas': 'No se puede cargar GeoAreas',
  'Cannot retrieve Critical Risks': 'No se pueden recuperar los riesgos críticos',
  'Cannot retrieve Go To list': 'No se puede recuperar ´Ir a la lista´',
  'Cannot retrieve company list.': 'No se puede recuperar lista',
  'Cannot retrieve map overlays': 'No se pueden recuperar superposiciones de mapas',
  'Chinese, Simplified': 'Chino, simplificado',
  'Chinese, Simplified (简化字)': 'Chino',
  'Coach': 'Coach',
  'Company Structure': 'Estructura de la compañía',
  'Compliant Verification': 'Cumplimiento',
  'Compliant Verification Group': 'Cumplimiento Grupo de verificación',
  'Confined Spaces': 'Espacio confinado',
  'Contact with Electricity': 'Contacto con Energía Eléctrica',
  'Contact with Molten Material': 'Contacto con material fundido',
  'Coordinates': 'Coordenadas',
  'Critical Control': 'Control Crítico',
  'Critical Control Status': 'estado Control Crítico',
  'Critical Risk': 'Riesgo Crítico',
  'Date Range': 'Rango de fechas',
  'Drowning': 'Ahogo',
  'Dutch (Nederlands)': 'holandés (Nederlands)',
  'English (English)': 'Inglés (English)',
  'Entanglement and Crushing': 'Atrapamiento y Chancado',
  'Entanglement in Rotating Equipment': 'Enredo en equipos rotativos',
  'Error': 'Error',
  'Event in a Confined Space': 'Evento en un espacio confinado',
  'Explosives Handling': 'Manejo de explosivos',
  'Exposure to Chemicals that Burn': 'Exposición a sustancias químicas que queman',
  'Exposure to Hazardous Substances': 'Exposición a Sustancias Peligrosas',
  'Exposure to Hazardous Substances (Chronic)': 'Exposición a Sustancias Peligrosas (Crónica)',
  'Exposure to Thermal Extremes': 'Exposição a Extremos Térmicos',
  'Fall from Height': 'Caida desde altura',
  'Fall of Ground - Surface': 'Caída de tierra - superficie',
  'Fall of Ground - Underground': 'Caída de tierra - subterráneo',
  'Falling Objects': 'Caída de Objetos',
  'Filter by Company Structure': 'Filtrar por Estructura de la Compañía',
  'Filter by Critical Risk': 'Filtrar por Control Crítico',
  'Filter by Date': 'Filtrar por Fecha',
  'Filter by GeoArea': 'Filtrado por GeoArea',
  'Filter by Site': 'Filtrar por Sitio',
  'Filter by Site Level': 'Filtrar por nivel del sitio',
  'Filter by Verification Type': 'Filtrar Por Tipo de Verificación',
  'French (Français)': 'Francés (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Alemán (Deutsch)',
  'Go': 'Ir',
  'Goto': 'Ir',
  'Goto Coordinates': 'Ir a Coordenadas',
  'Goto GeoArea': 'Ir a GeoArea',
  'Grizzly Bear Interactions': 'Interacciones del oso grizzly',
  'Hazardous Material': 'Material Peligroso',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Evento de vehículos pesados: vuelco, sobre el borde',
  'Icelandic (Íslensku)': 'Islandés (Íslensku)',
  'Incident Date': 'Fecha Incidente',
  'Incident Details': 'Detalles del incidente',
  'Incident During Tire Handling': 'Incidente durante el manejo de neumáticos',
  'Italian (Italiano)': 'Italiano (Italiano)',
  'Landscape': 'Paisaje',
  'Language': 'Idiomas',
  'Legend': 'Leyenda',
  'Level': 'Nivel',
  'Lifting Operations': 'Maniobras de izaje',
  'Loading Map Data': 'Cargando datos de mapa',
  'Location': 'Ubicación',
  'Logout': 'Salir',
  'Malaria': 'Malaria',
  'Manager Verification': 'Verificación de Gerente',
  'Message': 'Mensaje',
  'Mongolian (Монгол хэл)': 'Mongol (Монгол хэл)',
  'Mooring Operations': 'Operaciones de Amarre',
  'No incident results found. Please try changing the date range.': 'No se encontraron resultados de incidentes. Por favor intente cambiar el rango de fechas.',
  'No verification results found. Please changing the date range.': 'No se han encontrado verificacionesn. Por favor, cambie el rango de fechas.',
  'Non Compliant Verification': 'No Cumplimiento',
  'Non Compliant Verification Group': 'No Cumplimiento Grupo de verificación',
  'Norwegian (Norsk)': 'Noruego (Norsk)',
  'Operator Task Based Verification': 'Verificación de Operador Basado en Tareas',
  'Operator Verification': 'Verificación de Operador',
  'Orientation': 'Orientación',
  'Paper Size': 'Tamaño de papel',
  'Physical Location': 'Lugar de la Verificación',
  'Please note: Changing these options prepares the map for printing.': 'Tenga presente que al cambiar estas opciones se prepara el mapa para imprimir',
  'Portrait': 'Retrato',
  'Portuguese (Português)': 'Portugués (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Incidente con Energía',
  'Potential Fatality Incident - Zero Energy': 'Incidente con Energía Cero',
  'Print': 'Imprimir',
  'Print Data': 'Imprimir datos',
  'Print Map': 'Imprimir Mapa',
  'Print Options': 'Opciones de impresión',
  'Print Preview': 'Vista previa de impresión',
  'Rail Collision': 'Colisión ferroviaria',
  'Rail Impact on Person': 'Impacto persona tren',
  'Repeated Potential Fatality Incident - Energy': 'Repita el incidente con energía',
  'Repeated Potential Fatality Incident - Zero Energy': 'Repita el incidente con energía cero',
  'Russian (русский)': 'Ruso (русский)',
  'Select / Deselect All': 'Seleccionar / Deseleccionar',
  'Select a Company Structure': 'Seleccione una estructura de la compañía',
  'Select a GeoArea': 'Seleccione un GeoArea',
  'Select a Site': 'Seleccione una Operación / Area',
  'Serbian (Српски)': 'Serbio (Српски)',
  'Site': 'Area',
  'Slope Failure': 'Falla de Taludes',
  'Sorry, we couldn\'t load incidents. Please try reloading the page.': 'Lo sentimos, no pudimos cargar incidentes. Por favor, intente volver a cargar la página.',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Lo sentimos, no pudimos cargar verificaciones. Por favor, intente volver a cargar la página',
  'Spanish (Español)': 'Español (Español)',
  'Struck by Falling Object': 'Golpeado por un objeto que cae',
  'Supervisor Task Based Verification': 'Verificación de Supervisor Basado en Tareas',
  'Supervisor Verification': 'Verificación de Supervisor',
  'Surface': 'Superficie',
  'US Legal': 'Legal de EE. UU.',
  'US Letter': 'Carta de los Estados Unidos',
  'Unauthorised': 'No autorizado',
  'Uncontrolled Load During Lifting': 'Carga no controlada durante la elevación',
  'Uncontrolled Release of Energy': 'Accidente por liberación descontrolada de energía',
  'Underground Fire': 'Fuego Subterráneo',
  'Underground Fire or Explosion': 'Fuego subterráneo o explosión',
  'Underground Hazardous Atmosphere': 'Atmósfera peligrosa subterránea',
  'Underground Hoisting': 'Izaje Subterráneo',
  'Underground Inrush': 'Avalancha bajo tierra',
  'Underground Rock Fall': 'Desprendimiento de rocas subterráneo',
  'Unplanned Initiation of Explosives': 'Iniciación no planificada de explosivos',
  'Vehicle Collision (Heavy and Light)': 'Colisión de vehículos (pesado y ligero)',
  'Vehicle Collision or Rollover': 'Colisión o Vuelco de Vehículos',
  'Vehicle Impact on Person': 'Impacto de vehículos en personas',
  'Vehicle Incident': 'Incidente del vehículo',
  'Vehicle Pedestrian Interaction - Surface': 'Interacción de peatones con vehículos: superficie',
  'Vehicle Pedestrian Interaction - Underground': 'Interacción de peatones con vehículos subterráneos',
  'Verification Date': 'Fecha de Verificación',
  'Verification Details': 'Detalles Verificación',
  'Verification Type': 'Tipo de verificación',
  'Verifier': 'Verificador',
  'View Incident': 'Ver Incidente',
  'View Verification': 'Ver Verificación',
  'Warning': 'Advertencia',
  'Finnish (Suomalainen)': 'Finlandesa (Suomalainen)',
  'Indonesian (Bahasa Indonesia)': 'Indonesia (Bahasa Indonesia)',
  'You do not have access to this application. Please contact your site administrator': 'No tienes acceso a esta aplicación. Por favor, póngase en contacto con el administrador del sitio',
  'You will have to repeat these settings once you hit "Print" below': 'Tendrá que repetir estos ajustes una vez que presione "Imprimir" abajo',
};
