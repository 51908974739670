export const criticalRisks = {
  11: 'Contact with Electricity',
  34: 'Lifting Operations',
  39: 'Confined Spaces',
  49: 'Fall from Height',
  61: 'Entanglement and Crushing',
  71: 'Vehicle Impact on Person',
  76: 'Exposure to Hazardous Substances',
  101: 'Uncontrolled Release of Energy',
  107: 'Falling Objects',
  264: 'Contact with Molten Material',
  254: 'Slope Failure',
  265: 'Unplanned Initiation of Explosives',
  266: 'Vehicle Collision or Rollover',
  937: 'Drowning',
  954: 'Underground Hazardous Atmosphere',
  31640: 'Grizzly Bear Interactions',
  2278: 'Rail Collision',
  2279: 'Rail Impact on Person',
  264: 'Contact with Molten Material',
  316: 'Exposure to Thermal Extremes',
  7889: 'Mooring Operations',
  28996: 'Malaria',
  30765: 'Exposure to Hazardous Substances (Chronic)',
};

export const verificationTypes = [
  'Manager Verification',
  'Operator Task Based Verification',
  'Operator Verification',
  'Supervisor Task Based Verification',
  'Supervisor Verification',
];
