export default {
  proxy_path: "https://xlrk244plc.execute-api.us-east-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://r0kj8ass3h.execute-api.us-east-1.amazonaws.com/api",
  google_api_key: "AIzaSyDVZjTV9W6b88zZ1cSax2y9SVBUckHtjCo",
  main_company_term_id: "191",
  crm_base_url: "https://crm.testing.riotinto.forwoodsafety.com",
  forwood_id_url: "https://id.testing.riotinto.forwoodsafety.com?redirect_uri=https://mapreport.testing.riotinto.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.testing.riotinto.forwoodsafety.com",
  Auth: {
    userPoolId: "us-east-1_mTKeit2kD",
    userPoolWebClientId: "is4arap5ibdr116e0cc95r1s1",
    cookieStorage: {
      domain: ".testing.riotinto.forwoodsafety.com",
      secure: true
    }
  }
};
