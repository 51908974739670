import en from '../Translations/en';
import fr from '../Translations/fr';
import es from '../Translations/es';
import pt from '../Translations/pt';
import ru from '../Translations/ru';
import nb from '../Translations/nb';
import nl from '../Translations/nl';
import zh from '../Translations/zh-Hans';
import de from '../Translations/de';
import it from '../Translations/it';
import is from '../Translations/is';
import fi from '../Translations/fi';
import id from '../Translations/id';
import ja from '../Translations/ja';
import tr from '../Translations/tr';

/* Don't know why, but it fails if not imported this way */
import mn from '../Translations/mn';
import sr from '../Translations/sr';

const translations = {en, fr, es, mn, pt, ru, nb, zh, nl, de, is, it, sr, fi, id, ja, tr};

/*
 * Translations work by loading the full set of the desired translations
 * into the state, giving access to translations to any code that has
 * access to the state.
 */
export default (state = {}, action) => {
  switch (action.type) {
    case 'LANGUAGE_SET':
      return {
        ...state,
        locale: translations[action.locale] ? action.locale : 'en',
        t: translations[action.locale] || en,
      };

    default:
      return state;
  }
};
