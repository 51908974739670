module.exports = {
  'A3': ' A3',
  'A4': ' A4',
  'A5': ' A5',
  'All Sites': 'Semua Situs',
  'Assisting Verifiers': 'Pemeriksa Pembantu',
  'COVID-19 (Coronavirus)': 'COVID-19 (Virus Korona)',
  'Cannot load GeoAreas': 'Tidak dapat memuat GeoArea',
  'Cannot retrieve Critical Risks': 'Tidak dapat mengambil Risiko Kritis',
  'Cannot retrieve Go To list': 'Tidak dapat mengambil daftar Go To',
  'Cannot retrieve company list.': 'Tidak dapat mengambil daftar perusahaan.',
  'Cannot retrieve map overlays': 'Tidak dapat mengambil hamparan peta',
  'Chinese, Simplified': 'Cina disederhanakan',
  'Chinese, Simplified (简化字)': 'Cina disederhanakan (简化字)',
  'Coach': 'Pelatih',
  'Company Structure': 'Struktur perusahaan',
  'Compliant Verification': 'Verifikasi yang Sesuai',
  'Compliant Verification Group': 'Grup Verifikasi yang Sesuai',
  'Confined Spaces': 'Ruang terbatas',
  'Contact with Electricity': 'Kontak dengan Listrik',
  'Contact with Molten Material': 'Kontak dengan Bahan Cair',
  'Coordinates': 'Koordinat',
  'Critical Control': 'Kontrol Kritis',
  'Critical Control Status': 'Status Kontrol Kritis',
  'Critical Risk': 'Risiko Kritis',
  'Date Range': 'Rentang Tanggal',
  'Drowning': 'Tenggelam',
  'Dutch (Nederlands)': 'Belanda (Dutch)',
  'English (English)': 'Inggris (English)',
  'Entanglement and Crushing': 'Keterikatan dan Penghancuran',
  'Entanglement in Rotating Equipment': 'Keterikatan dalam Peralatan Berputar',
  'Error': 'Kesalahan',
  'Event in a Confined Space': 'Acara di Ruang Terbatas',
  'Explosives Handling': 'Penanganan Bahan Peledak',
  'Exposure to Chemicals that Burn': 'Paparan Bahan Kimia yang Membakar',
  'Exposure to Hazardous Substances': 'Paparan Zat Berbahaya',
  'Exposure to Hazardous Substances (Chronic)': 'Paparan Zat Berbahaya (Kronis)',
  'Exposure to Thermal Extremes': 'Paparan Termal Ekstrem',
  'Fall from Height': 'Jatuh dari Ketinggian',
  'Fall of Ground - Surface': 'Jatuhnya Tanah - Permukaan',
  'Fall of Ground - Underground': 'Fall of Ground - Bawah Tanah',
  'Falling Objects': 'Benda Jatuh',
  'Filter by Company Structure': 'Filter menurut Struktur Perusahaan',
  'Filter by Critical Risk': 'Filter menurut Risiko Kritis',
  'Filter by Date': 'Filter menurut Tanggal Verifikasi',
  'Filter by GeoArea': 'Filter menurut GeoArea',
  'Filter by Site': 'Filter menurut Situs',
  'Filter by Site Level': 'Filter menurut Tingkat Situs',
  'Filter by Verification Type': 'Filter menurut Jenis Verifikasi',
  'French (Français)': 'Prancis (French)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Jerman (German)',
  'Go': 'Pergi',
  'Goto': 'Goto',
  'Goto Coordinates': 'Koordinat Goto',
  'Goto GeoArea': 'Goto GeoArea',
  'Grizzly Bear Interactions': 'Interaksi Beruang Grizzly',
  'Hazardous Material': 'Bahan Berbahaya',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Acara Kendaraan Berat - Rollover, Over Edge',
  'Icelandic (Íslensku)': 'Islandia (Íslensku)',
  'Incident Date': 'Tanggal Insiden',
  'Incident Details': 'Detail Insiden',
  'Incident During Tire Handling': 'Insiden Selama Penanganan Ban',
  'Italian (Italiano)': 'Italia (Italian)',
  'Landscape': 'Lanskap',
  'Language': 'Bahasa',
  'Legend': 'Legenda',
  'Level': 'Tingkat',
  'Lifting Operations': 'Operasi Pengangkatan',
  'Loading Map Data': 'Memuat',
  'Location': 'Lokasi',
  'Logout': 'Keluar',
  'Malaria': 'Malaria',
  'Manager Verification': 'Verifikasi Manajer',
  'Message': 'Pesan',
  'Mongolian (Монгол хэл)': 'Mongolia (Mongolian)',
  'Mooring Operations': 'Operasi Penambatan',
  'No incident results found. Please try changing the date range.': 'Tidak ada hasil insiden yang ditemukan. Coba ubah rentang tanggal.',
  'No verification results found. Please try changing the date range.': 'Tidak ada hasil verifikasi yang ditemukan. Coba ubah rentang tanggal.',
  'Non Compliant Verification': 'Verifikasi Tidak Sesuai',
  'Non Compliant Verification Group': 'Grup Verifikasi Tidak Sesuai',
  'Norwegian (Norsk)': 'Norwegia (Norwegian)',
  'Operator Task Based Verification': 'Verifikasi Berbasis Tugas Operator',
  'Operator Verification': 'Verifikasi Operator',
  'Orientation': 'Orientasi',
  'Paper Size': 'Ukuran kertas',
  'Physical Location': 'Lokasi Fisik',
  'Please note: Changing these options prepares the map for printing.': 'Mengubah opsi ini mempersiapkan peta untuk dicetak.',
  'Portrait': 'Potret',
  'Portuguese (Português)': 'Portugis (Portuguese)',
  'Potential Fatality Incident - Energy': 'Insiden dengan Energi',
  'Potential Fatality Incident - Zero Energy': 'Insiden dengan Zero Energy',
  'Print': 'Mencetak',
  'Print Data': 'Cetak Data',
  'Print Map': 'Cetak Halaman Ini',
  'Print Options': 'Opsi Cetak',
  'Print Preview': 'Tinjauan Cetak',
  'Rail Collision': 'Tabrakan Rel',
  'Rail Impact on Person': 'Dampak Rel pada Orang',
  'Repeated Potential Fatality Incident - Energy': 'Ulangi Insiden dengan Energi',
  'Repeated Potential Fatality Incident - Zero Energy': 'Ulangi Insiden dengan Energi Nol',
  'Russian (русский)': 'Rusia (Russian)',
  'Select / Deselect All': 'Pilih / Hapus Semua Pilihan',
  'Select a Company Structure': 'Pilih Struktur Perusahaan',
  'Select a GeoArea': 'Pilih GeoArea',
  'Select a Site': 'Pilih Situs',
  'Serbian (Српски)': 'Serbia (Serbian)',
  'Site': 'Lokasi',
  'Slope Failure': 'Kegagalan Lereng',
  'Sorry, we couldn\'t load incidents. Please try reloading the page': 'Maaf, kami tidak dapat memuat insiden. Silakan coba memuat ulang halaman',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Maaf, kami tidak dapat memuat verifikasi. Silakan coba memuat ulang halaman',
  'Spanish (Español)': 'Spanyol (Spanish)',
  'Struck by Falling Object': 'Disambar Benda Jatuh',
  'Supervisor Task Based Verification': 'Verifikasi Berbasis Tugas Supervisor',
  'Supervisor Verification': 'Verifikasi Pengawas',
  'Surface': 'Permukaan',
  'US Legal': 'Hukum AS',
  'US Letter': 'Surat AS',
  'Unauthorised': 'Tidak Sah',
  'Uncontrolled Load During Lifting': 'Beban Tidak Terkendali Selama Pengangkatan',
  'Uncontrolled Release of Energy': 'Pelepasan Energi yang Tidak Terkendali',
  'Underground Fire': 'Kebakaran Bawah Tanah',
  'Underground Fire or Explosion': 'Kebakaran Bawah Tanah atau Ledakan',
  'Underground Hazardous Atmosphere': 'Suasana Berbahaya Bawah Tanah',
  'Underground Hoisting': 'Pengangkatan Bawah Tanah',
  'Underground Inrush': 'Arus Masuk Bawah Tanah',
  'Underground Rock Fall': 'Air Terjun Batu Bawah Tanah',
  'Unplanned Initiation of Explosives': 'Inisiasi Bahan Peledak yang Tidak Direncanakan',
  'Vehicle Collision (Heavy and Light)': 'Tabrakan Kendaraan (Berat dan Ringan)',
  'Vehicle Collision or Rollover': 'Tabrakan atau Rollover Kendaraan',
  'Vehicle Impact on Person': 'Dampak Kendaraan pada Orang',
  'Vehicle Incident': 'Insiden Kendaraan',
  'Vehicle Pedestrian Interaction - Surface': 'Interaksi Pejalan Kaki Kendaraan - Permukaan',
  'Vehicle Pedestrian Interaction - Underground': 'Interaksi Pejalan Kaki Kendaraan - Bawah Tanah',
  'Verification Date': 'Tanggal Verifikasi',
  'Verification Details': 'Detail Verifikasi',
  'Verification Type': 'Jenis Verifikasi',
  'Verifier': 'Pemverifikasi',
  'View Incident': 'Lihat Insiden',
  'View Verification': 'Lihat Verifikasi',
  'Warning': 'Peringatan',
  'Finnish (Suomalainen)': 'Finlandia (Finnish)',
  'Indonesian (Bahasa Indonesia)': 'Bahasa Indonesia',
  'You do not have access to this application. Please contact your site administrator.': 'Anda tidak memiliki akses ke aplikasi ini. Silakan hubungi administrator situs Anda.',
  'You will have to repeat these settings once you hit \"Print\" below': 'Anda harus mengulangi pengaturan ini setelah Anda menekan "Cetak" di bawah'
};
