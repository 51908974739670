module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Alle Standorte',
  'Assisting Verifiers': 'Unterstützende Prüfer',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'GeoAreas können nicht geladen werden',
  'Cannot retrieve Critical Risks': 'Kritische Risiken können nicht abgerufen werden',
  'Cannot retrieve Go To list': '"Gehe zu" Liste kann nicht abgerufen werden',
  'Cannot retrieve company list.': 'Firmenliste kann nicht abgerufen werden.',
  'Cannot retrieve map overlays': 'Kartenüberlagerungen können nicht abgerufen werden',
  'Chinese, Simplified': 'Vereinfachtes Chinesisch',
  'Chinese, Simplified (简化字)': 'Vereinfachtes Chinesisch (简化字)',
  'Coach': 'Trainer',
  'Company Structure': 'Unternehmensstruktur',
  'Compliant Verification': 'Konforme Überprüfung',
  'Compliant Verification Group': 'Konforme Überprüfungsgruppe',
  'Confined Spaces': 'Enge Räume',
  'Contact with Electricity': 'Kontakt mit Elektrizität',
  'Contact with Molten Material': 'Kontakt mit geschmolzenem Material',
  'Coordinates': 'Koordinaten',
  'Critical Control': 'Kritische Kontrolle',
  'Critical Control Status': 'Kritischer Kontrollstatus',
  'Critical Risk': 'Kritisches Risiko',
  'Date Range': 'Datumsbereich',
  'Drowning': 'Ertrinken',
  'Dutch (Nederlands)': 'Niederländisch (Nederlands)',
  'English (English)': 'Englisch (English)',
  'Entanglement and Crushing': 'Verstrickung und Quetschen',
  'Entanglement in Rotating Equipment': 'Verstrickung in rotierenden Geräten',
  'Error': 'Error',
  'Event in a Confined Space': 'Ereignis auf engstem Raum',
  'Explosives Handling': 'Umgang mit Sprengstoffen',
  'Exposure to Chemicals that Burn': 'Umgang mit brennenden Chemikalien',
  'Exposure to Hazardous Substances': 'Umgang mit gefährlichen Stoffen',
  'Exposure to Hazardous Substances (Chronic)': 'Umgang mit gefährlichen Stoffen (chronisch)',
  'Exposure to Thermal Extremes': 'Umgang mit Temperatur Spitzen',
  'Fall from Height': 'Absturz',
  'Fall of Ground - Surface': 'Geländeneigung - Oberfläche',
  'Fall of Ground - Underground': 'Geländeneigung  - Untergrund',
  'Falling Objects': 'Herabfallende Gegenstände',
  'Filter by Company Structure': 'Filtern nach Unternehmensstruktur',
  'Filter by Critical Risk': 'Filtern nach kritischem Risiko',
  'Filter by Date': 'Nach Überprüfungsdatum filtern',
  'Filter by GeoArea': 'Filtern nach GeoArea',
  'Filter by Site': 'Nach Standort filtern',
  'Filter by Site Level': 'Nach Standorte-Ebene filtern',
  'Filter by Verification Type': 'Nach Überprüfungstyp filtern',
  'French (Français)': 'Französisch (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Deutsch (Deutsch)',
  'Go': 'Gehen',
  'Goto': 'Gehe zu',
  'Goto Coordinates': 'Gehe zu Koordinaten',
  'Goto GeoArea': 'Gehe zu GeoArea',
  'Grizzly Bear Interactions': 'Grizzlybär Interaktionen',
  'Hazardous Material': 'Gefahrengut',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Schwerer Fahrzeugunfall - Überschlag, über Kante',
  'Icelandic (Íslensku)': 'Isländisch (Íslensku)',
  'Incident Date': 'Vorfalldatum',
  'Incident Details': 'Details zu Vorfällen',
  'Incident During Tire Handling': 'Vorfall während der Reifenhandhabung',
  'Italian (Italiano)': 'Italienisch (Italiano)',
  'Landscape': 'Landschaft',
  'Language': 'Sprache',
  'Legend': 'Legende',
  'Level': 'Niveau',
  'Lifting Operations': 'Hebevorgänge',
  'Loading Map Data': 'Wird geladen',
  'Location': 'Ort',
  'Logout': 'Ausloggen',
  'Malaria': 'Malaria',
  'Manager Verification': 'Manager Überprüfung',
  'Message': 'Botschaft',
  'Mongolian (Монгол хэл)': 'Mongolisch (Монгол хэл)',
  'Mooring Operations': 'Vertäuung',
  'No incident results found. Please try changing the date range.': 'Es wurden keine Vorfallergebnisse gefunden. Bitte versuchen Sie den Datumsbereich zu ändern.',
  'No verification results found. Please try changing the date range.': 'Keine Überprüfungsergebnisse gefunden. Bitte versuchen Sie den Datumsbereich zu ändern.',
  'Non Compliant Verification': 'Nicht konforme Überprüfung',
  'Non Compliant Verification Group': 'Nicht konforme Überprüfungsgruppe',
  'Norwegian (Norsk)': 'Norwegisch (Norsk)',
  'Operator Task Based Verification': 'Bediener Aufgabenbasierte Überprüfung',
  'Operator Verification': 'Bediener Überprüfung',
  'Orientation': 'Orientierung',
  'Paper Size': 'Papier größe',
  'Physical Location': 'Physischer Standort',
  'Please note: Changing these options prepares the map for printing.': 'Bitte beachten Sie: Durch Ändern dieser Optionen wird die Karte für den Druck vorbereitet.',
  'Portrait': 'Porträt',
  'Portuguese (Português)': 'Portugiesisch (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Vorfall mit Energie',
  'Potential Fatality Incident - Zero Energy': 'Vorfall ohne Energie',
  'Print': 'Drucken',
  'Print Data': 'Daten drucken',
  'Print Map': 'Karte drucken',
  'Print Options': 'Druckoptionen',
  'Print Preview': 'Druckvorschau',
  'Rail Collision': 'Zugkollision',
  'Rail Impact on Person': 'Zugunfall mit Personenschaden',
  'Repeated Potential Fatality Incident - Energy': 'Wiederholter Vorfall mit Energie',
  'Repeated Potential Fatality Incident - Zero Energy': 'Wiederholter Vorfall ohne Energie',
  'Russian (русский)': 'Russisch (русский)',
  'Select / Deselect All': 'Alle auswählen / abwählen',
  'Select a Company Structure': 'Wählen Sie eine Unternehmensstruktur',
  'Select a GeoArea': 'Wählen Sie eine GeoArea aus',
  'Select a Site': 'Wählen Sie einen Standort aus',
  'Serbian (Српски)': 'Serbisch (Српски)',
  'Site': 'Standort',
  'Slope Failure': 'Hangversagen',
  'Sorry, we couldn\'t load incidents. Please try reloading the page': 'Leider konnten wir keine Vorfälle laden. Bitte versuchen Sie, die Seite neu zu laden',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Leider konnten wir keine Überprüfungen laden. Bitte versuchen Sie, die Seite neu zu laden',
  'Spanish (Español)': 'Spanisch (Español)',
  'Struck by Falling Object': 'Unfall durch Herabfallende Gegenstände',
  'Supervisor Task Based Verification': 'Vorarbeiter aufgabenbasiert Überprüfung',
  'Supervisor Verification': 'Vorarbeiter Überprüfung',
  'Surface': 'Oberfläche',
  'US Legal': 'US Legal',
  'US Letter': 'US Brief',
  'Unauthorised': 'Nicht autorisiert',
  'Uncontrolled Load During Lifting': 'Unkontrollierte Last beim Heben',
  'Uncontrolled Release of Energy': 'Unkontrollierte Energiefreisetzung',
  'Underground Fire': 'Unterirdisches Feuer',
  'Underground Fire or Explosion': 'Unterirdisches Feuer oder Explosion',
  'Underground Hazardous Atmosphere': 'Unterirdische gefährliche Atmosphäre',
  'Underground Hoisting': 'Unterirdisches Heben',
  'Underground Inrush': 'Unter Tage Einbruch',
  'Underground Rock Fall': 'Unter Tage Steinschlag',
  'Unplanned Initiation of Explosives': 'Ungeplante Sprengstoffzündung',
  'Vehicle Collision (Heavy and Light)': 'Fahrzeugkollision (schwer und leicht)',
  'Vehicle Collision or Rollover': 'Fahrzeugkollision oder Überschlag',
  'Vehicle Impact on Person': 'Fahrzeugunfall mit Personenschaden',
  'Vehicle Incident': 'Fahrzeugunfall',
  'Vehicle Pedestrian Interaction - Surface': 'Fahrzeug Fußgänger Interaktion - Oberfläche',
  'Vehicle Pedestrian Interaction - Underground': 'Fahrzeug Fußgänger Interaktion - Untergrund',
  'Data di verifica': 'Überprüfungsdatum',
  'Verification Details': 'Details zur Überprüfung',
  'Verification Type': 'Überprüfungstyp',
  'Verifier': 'Prüfer',
  'View Incident': 'Vorfall anzeigen',
  'View Verification': 'Überprüfung anzeigen',
  'Warning': 'Warnung',
  'Finnish (Suomalainen)': 'Finnisch (Suomalainen)',
  'Indonesian (Bahasa Indonesia)': 'Indonesisch (Bahasa Indonesia)',
  'You do not have access to this application. Please contact your site administrator.': 'Sie haben keinen Zugriff auf diese Anwendung. Bitte wenden Sie sich an Ihren Standort-Administrator.',
  'You will have to repeat these settings once you hit "Print" below': 'Sie müssen diese Einstellungen wiederholen, sobald Sie unten auf "Drucken" klicken'
};
