import {notification} from 'antd';

/*
 * Uses antdesign notification to display notifications in the top right-hand
 * corner, normally used for error messages from API calls
 */
export default store => next => action => {
  next(action);

  /* Allows non-Component translation */
  const translate = text => store.getState().language.t[text] || text;

  if (
    (action.type === 'API_CALL_FINISHED' && action.status === 'error') ||
    action.type === 'NOTIFICATION_WARNING'
  ) {
    if (action.description) {
      notification.warning({
        message: translate('Warning'),
        description: translate(action.description),
      });
    }
  }
};
