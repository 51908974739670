import React from 'react';
import {connect} from 'react-redux';

/*
 * Imported directly since these components will be required regardless
 * of the state of the application
 */
import ResponsiveLayout from './Containers/ResponsiveLayout';
import Layout from './Containers/Layout';
import Loading from './Components/Loading';

import './App.css';

/*
 * Lazy Loaded - only necessary in certain states. The conditional logic in the
 * render function below e.g. {isDataLoaded && <Print />} ensures the component
 * is only requested in certain state, delaying load of code
 */
const Unauthorised = React.lazy(() => import('./Components/Unauthorised'));
const MapContent = React.lazy(() => import('./Components/MapContent'));
const Print = React.lazy(() => import('./Containers/Print'));
const ModalCompanyStructure = React.lazy(() =>
  import('./Components/ModalCompanyStructure'),
);

/*
 * Done as a class component rather than a pure function component simply to
 * get access to the componentDidMount() method to fire init() method on first
 * load of application.
 */
class App extends React.Component {
  componentDidMount() {
    /*
     * This is to allow the redux store to know the page has initialized, and to
     * start performing side-effects that are required to get the app to work.
     * Main example being the auth middleware.
     */
    this.props.init();
  }

  render() {
    const {isLoading, companyId, auth, isDataLoaded, mapReady} = this.props;

    if (auth.unauthorised) {
      return (
        <ResponsiveLayout>
          <Layout>
            <Unauthorised />
          </Layout>
        </ResponsiveLayout>
      );
    }

    return auth.username ? (
      <React.Fragment>
        {/*
          Sits behind the main content and is never actually visible to the user,
          but must be in the background for our print functionality to work correctly
        */}
        <div id="print-wrapper">
          <React.Suspense fallback={<span />}>
            {isDataLoaded && <Print />}
          </React.Suspense>
        </div>
        <ResponsiveLayout>
          <Layout>
            <div className="map-wrapper print" id="wrapper">
              {!isDataLoaded && isLoading && <Loading />}
              <React.Suspense fallback={<Loading />}>
                {!!companyId && <MapContent ready={mapReady} />}
                {!companyId && <ModalCompanyStructure />}
              </React.Suspense>
            </div>
          </Layout>
        </ResponsiveLayout>
      </React.Fragment>
    ) : null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    isDataLoaded: someDataIsLoaded(state.map),
    showPrint: state.modal === 'print',
    t: state.language.t,
    isLoading:
      state.map.loading['verifications'] || state.map.loading['incidents'],
    companyId: state.map.companyId,
    mapReady: state.map.mapReady,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    init(params) {
      dispatch({type: 'INIT', ...params});
    },
  };
};

/*
 * This function is basically saying "Is there markers (either verification
 * marker or incident marker) drawn on the map?" This is to ensure the
 * <MapContent /> component (our heaviest component) only loads when there
 * is something worth showing.
 */
const someDataIsLoaded = map =>
  map.verifications.compliantIds.length ||
  map.verifications.nonCompliantIds.length ||
  map.incidents.pfieIds.length ||
  map.incidents.pfierIds.length ||
  map.incidents.pfizeIds.length ||
  map.incidents.pfizerIds.length ||
  map.incidents.pfiefIds.length ||
  map.incidents.pfierfIds.length ||
  null;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
