import React from 'react';
import {Layout} from 'antd';

/*
 * The NavMenu component has been removed from here to save on bundle size.
 * Simply make the NavMenu variable be an import of Containers/NavMenu.js
 */
import FilterMenu from '../Containers/FilterMenu';
import Header from '../Containers/Header';
const EMPTY = () => <span />;
const NavMenu = EMPTY;

const {Footer, Content} = Layout;

export default ({isMobile, children, isReady}) => {
  const content = (
    <Content
      style={{
        position: 'relative',
        margin: '16px 16px 0 16px',
        background: '#fff',
      }}>
      {children}
    </Content>
  );

  /* JS Date.getYear() starts from 1900 for some reason */
  const year = new Date().getYear() + 1900;

  /* The antdesign <Layout /> component is best thought of as a "row".*/
  return (
    <Layout style={{height: '100%', width: '100%'}}>
      <React.Suspense fallback={<EMPTY />}>
        {isMobile ? (
          <Layout>
            <Header />
            <Layout style={{position: 'relative'}}>
              <NavMenu />
              {content}
              {isReady && <FilterMenu />}
            </Layout>
          </Layout>
        ) : (
          <React.Fragment>
            <Header />
            <Layout>
              <NavMenu />
              {content}
              {isReady && <FilterMenu />}
            </Layout>
            <Footer style={{textAlign: 'center'}}>
              Forwood Enterprises &copy; {year}
            </Footer>
          </React.Fragment>
        )}
      </React.Suspense>
    </Layout>
  );
};
