module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Tutti i siti',
  'Assisting Verifiers': 'Assistenza ai verificatori',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'Impossibile caricare GeoAreas',
  'Cannot retrieve Critical Risks': 'Impossibile recuperare i rischi critici',
  'Cannot retrieve Go To list': "Impossibile recuperare Vai all'elenco",
  'Cannot retrieve company list.': "Impossibile recuperare l'elenco delle società.",
  'Cannot retrieve map overlays': 'Impossibile recuperare gli overlay della mappa',
  'Chinese, Simplified': 'Cinese semplificato',
  'Chinese, Simplified (简化字)': 'Cinese semplificato (简化字)',
  'Coach': 'Allenatore',
  'Company Structure': 'Struttura aziendale',
  'Compliant Verification': 'Verifica conforme',
  'Compliant Verification Group': 'Gruppo di verifica conforme',
  'Confined Spaces': 'Spazi confinati',
  'Contact with Electricity': "Contatto con l'elettricità",
  'Contact with Molten Material': 'Contatto con materiale fuso',
  'Coordinates': 'Coordinata',
  'Critical Control': 'Controllo critico',
  'Critical Control Status': 'Stato del controllo critico',
  'Critical Risk': 'Rischio critico',
  'Date Range': 'Intervallo di date',
  'Drowning': 'Annegamento',
  'Dutch (Nederlands)': 'Olandese (Nederlands)',
  'English (English)': 'Inglese (English)',
  'Entanglement and Crushing': 'Entanglement e schiacciamento',
  'Entanglement in Rotating Equipment': 'Entanglement in apparecchiatura rotante',
  'Error': 'Errore',
  'Event in a Confined Space': 'Evento in uno spazio confinato',
  'Explosives Handling': 'Gestione degli esplosivi',
  'Exposure to Chemicals that Burn': 'Esposizione a sostanze chimiche che bruciano',
  'Exposure to Hazardous Substances': 'Esposizione a sostanze pericolose',
  'Exposure to Hazardous Substances (Chronic)': 'Esposizione a sostanze pericolose (croniche)',
  'Exposure to Thermal Extremes': 'Esposizione a temperature estreme',
  'Fall from Height': "Caduta dall'altezza",
  'Fall of Ground - Surface': 'Caduta di terra - Superficie',
  'Fall of Ground - Underground': 'Caduta di terra - Metropolitana',
  'Falling Objects': 'Caduta di oggetti',
  'Filter by Company Structure': 'Filtra per struttura aziendale',
  'Filter by Critical Risk': 'Filtra per Rischio critico',
  'Filter by Date': 'Filtra per Data di verifica',
  'Filter by GeoArea': 'Filtra per GeoArea',
  'Filter by Site': 'Filtra per sito',
  'Filter by Site Level': 'Filtra per livello del sito',
  'Filter by Verification Type': 'Filtra per Tipo di verifica',
  'French (Français)': 'Francese (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Tedesco (Deutsch)',
  'Go': 'Partire',
  'Goto': 'Vaia',
  'Goto Coordinates': 'Vaia Coordinata',
  'Goto GeoArea': 'Vaia GeoArea',
  'Grizzly Bear Interactions': 'Interazioni con Grizzly Bear',
  'Hazardous Material': 'Materiale pericoloso',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Evento per veicoli pesanti: ribaltamento, fuori bordo',
  'Icelandic (Íslensku)': 'Islandese (Íslensku)',
  'Incident Date': "Data dell'incidente",
  'Incident Details': "Dettagli dell'incidente",
  'Incident During Tire Handling': 'Incidente durante la gestione dei pneumatici',
  'Italian (Italiano)': 'Italiano (Italiano)',
  'Landscape': 'Paesaggio',
  'Language': 'linguaggio',
  'Legend': 'Leggenda',
  'Level': 'Livello',
  'Lifting Operations': 'Operazioni di sollevamento',
  'Loading Map Data': 'Caricamento in corso',
  'Location': 'Posizione',
  'Logout': 'Disconnettersi',
  'Malaria': 'Malaria',
  'Manager Verification': 'Verifica del gestore',
  'Message': 'Messaggio',
  'Mongolian (Монгол хэл)': 'Mongolo (Монгол хэл)',
  'Mooring Operations': 'Operazioni di ormeggio',
  'No incident results found. Please try changing the date range.': "Nessun risultato incidente trovato. Prova a modificare l'intervallo di date.",
  'No verification results found. Please try changing the date range.': "Nessun risultato di verifica trovato. Prova a modificare l'intervallo di date.",
  'Non Compliant Verification': 'Verifica non conforme',
  'Non Compliant Verification Group': 'Gruppo di verifica non conforme',
  'Norwegian (Norsk)': 'Norvegese (Norsk)',
  'Operator Task Based Verification': "Verifica basata sulle attività dell'operatore",
  'Operator Verification': "Verifica dell'operatore",
  'Orientation': 'Orientamento',
  'Paper Size': 'Dimensioni del foglio',
  'Physical Location': 'Luogo fisico',
  'Please note: Changing these options prepares the map for printing.': 'Nota: la modifica di queste opzioni prepara la mappa per la stampa.',
  'Portrait': 'Ritratto',
  'Portuguese (Português)': 'Portoghese (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Incidente con energia',
  'Potential Fatality Incident - Zero Energy': 'Incidente con energia zero',
  'Print': 'Stampa',
  'Print Data': 'Stampa dati',
  'Print Map': 'Stampa questa pagina',
  'Print Options': 'Opzioni di stampa',
  'Print Preview': 'Anteprima di stampa',
  'Rail Collision': 'Collisione su rotaia',
  'Rail Impact on Person': 'Impatto ferroviario su persona',
  'Repeated Potential Fatality Incident - Energy': "Ripeti l'incidente con energia",
  'Repeated Potential Fatality Incident - Zero Energy': "Ripeti l'incidente con energia zero",
  'Russian (русский)': 'Russa (русский)',
  'Select / Deselect All': 'Seleziona / Deseleziona tutto',
  'Select a Company Structure': 'Seleziona una struttura aziendale',
  'Select a GeoArea': 'Seleziona un GeoArea',
  'Select a Site': 'Seleziona un sito',
  'Serbian (Српски)': 'Serbo (Српски)',
  'Site': 'Posto',
  'Slope Failure': 'Pendenza del pendio',
  'Sorry, we couldn\'t load incidents. Please try reloading the page': 'Spiacenti, impossibile caricare incidenti. Prova a ricaricare la pagina',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Spiacenti, impossibile caricare le verifiche. Prova a ricaricare la pagina',
  'Spanish (Español)': 'Spagnolo (Español)',
  'Struck by Falling Object': 'Colpito da oggetto che cade',
  'Supervisor Task Based Verification': 'Verifica basata sulle attività del supervisore',
  'Supervisor Verification': 'Verifica del supervisore',
  'Surface': 'Superficie',
  'US Legal': 'Legale USA',
  'US Letter': 'US Lettera',
  'Unauthorised': 'Non autorizzato',
  'Uncontrolled Load During Lifting': 'Carico incontrollato durante il sollevamento',
  'Uncontrolled Release of Energy': 'Rilascio incontrollato di energia',
  'Underground Fire': 'Fuoco sotterraneo',
  'Underground Fire or Explosion': 'Incendio sotterraneo o esplosione',
  'Underground Hazardous Atmosphere': 'Atmosfera pericolosa sotterranea',
  'Underground Hoisting': 'Sollevamento sotterraneo',
  'Underground Inrush': 'Sbalzo sotterraneo',
  'Underground Rock Fall': 'Metropolitana caduta di roccia',
  'Unplanned Initiation of Explosives': 'Avvio non pianificato di esplosivi',
  'Vehicle Collision (Heavy and Light)': 'Collisione del veicolo (pesante e leggera)',
  'Vehicle Collision or Rollover': 'Collisione o ribaltamento del veicolo',
  'Vehicle Impact on Person': 'Impatto del veicolo sulla persona',
  'Vehicle Incident': 'Incidente del veicolo',
  'Vehicle Pedestrian Interaction - Surface': 'Interazione pedonale del veicolo - Superficie',
  'Vehicle Pedestrian Interaction - Underground': 'Interazione pedonale del veicolo - Metropolitana',
  'Verification Date': 'Data di verifica',
  'Verification Details': 'Dettagli di verifica',
  'Verification Type': 'Tipo di verifica',
  'Verifier': 'Verifier',
  'View Incident': 'Visualizza incidente',
  'View Verification': 'Visualizza verifica',
  'Warning': 'Avvertimento',
  'Finnish (Suomalainen)': 'Finlandese (Suomalainen)',
  'You do not have access to this application. Please contact your site administrator.': "Non hai accesso a questa applicazione. Si prega di contattare l'amministratore del sito.",
  'You will have to repeat these settings once you hit "Print" below': 'Dovrai ripetere queste impostazioni una volta premuto "Stampa" di seguito',
  'Indonesian (Bahasa Indonesia)': 'Indonesiana (Bahasa Indonesia)'
};
