module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Все сайты',
  'Assisting Verifiers': 'Помощник верификатора',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'Не удается загрузить GeoAreas',
  'Cannot retrieve Critical Risks': 'Не удается получить критические риски',
  'Cannot retrieve Go To list': 'Невозможно выполнить команду "Перейти к"',
  'Cannot retrieve company list.': 'Не удается получить список компаний.',
  'Cannot retrieve map overlays': 'Не удается получить наложения карты',
  'Chinese, Simplified': 'Китайский упрощённый',
  'Chinese, Simplified (简化字)': 'китайский (简化字)',
  'Coach': 'тренер',
  'Company Structure': 'Структура компании',
  'Compliant Verification': 'Соответствие требованиям',
  'Compliant Verification Group': 'Совместимая группа проверки',
  'Confined Spaces': 'Ограниченное пространство',
  'Contact with Electricity': 'Связаться с электричеством',
  'Contact with Molten Material': 'Контакт с расплавленным материалом',
  'Coordinates': 'Координаты',
  'Critical Control': 'Критический контроль',
  'Critical Control Status': 'Критический контроль',
  'Critical Risk': 'Критический риск',
  'Date Range': 'Интервалы Дат',
  'Drowning': 'Утопление',
  'Dutch (Nederlands)': 'нидерландский язык (Nederlands)',
  'English (English)': 'Английский (English)',
  'Entanglement and Crushing': 'Запутывание и дробление',
  'Entanglement in Rotating Equipment': 'Запутывание в вращающемся оборудовании',
  'Error': 'Ошибка',
  'Event in a Confined Space': 'Событие в замкнутом пространстве',
  'Explosives Handling': 'Обработка взрывчатых веществ',
  'Exposure to Chemicals that Burn': 'Воздействие химических веществ, которые горят',
  'Exposure to Hazardous Substances': 'Воздействие опасных веществ',
  'Exposure to Hazardous Substances (Chronic)': 'Воздействие опасных веществ (хроническое)',
  'Exposure to Thermal Extremes': 'Воздействие экстремальных температур',
  'Fall from Height': 'Падение с высоты',
  'Fall of Ground - Surface': 'Падение Земли - Поверхность',
  'Fall of Ground - Underground': 'Падение Земли - Подземный',
  'Falling Objects': 'Падающие объекты',
  'Filter by Company Structure': 'Фильтр по структуре компании',
  'Filter by Critical Risk': 'Фильтр с критическим риском',
  'Filter by Date': 'Фильтровать по дате',
  'Filter by GeoArea': 'Сортировать по GeoArea',
  'Filter by Site': 'Фильтровать по сайту',
  'Filter by Site Level': 'Фильтровать по сайту',
  'Filter by Verification Type': 'Фильтровать по типу проверки',
  'French (Français)': 'Французский (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Немецкий (Deutsch)',
  'Go': 'Идти',
  'Goto': 'Перейти к',
  'Goto Coordinates': 'Перейти к координатам',
  'Goto GeoArea': 'Перейти к GeoArea',
  'Grizzly Bear Interactions': 'Гризли Взаимодействия',
  'Hazardous Material': 'Опасный материал',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Событие для тяжелой техники - переворачивание, через край',
  'Icelandic (Íslensku)': 'исландский (Íslensku)',
  'Incident Date': 'Дата инцидента',
  'Incident Details': 'Сведения об инциденте',
  'Incident During Tire Handling': 'Инцидент во время обработки шин',
  'Italian (Italiano)': 'итальянский (Italiano)',
  'Landscape': 'Горизонтальная',
  'Language': 'Языки',
  'Legend': 'легенда',
  'Level': 'Уровень',
  'Lifting Operations': 'Подъемные работы',
  'Loading Map Data': 'Загрузка данных карты',
  'Location': 'Место',
  'Logout': 'Выйти',
  'Malaria': 'малярия',
  'Manager Verification': 'Проверка менеджера',
  'Message': 'Сообщение',
  'Mongolian (Монгол хэл)': 'Монгольский (Монгол хэл)',
  'Mooring Operations': 'Швартовые операции',
  'No incident results found. Please try changing the date range.': 'Результаты не найдены. Попробуйте изменить диапазон дат.',
  'No verification results found. Please changing the date range.': 'Результаты проверки не найдены. Пожалуйста, измените диапазон дат.',
  'Non Compliant Verification': 'Неподходящая проверка',
  'Non Compliant Verification Group': 'Неверная группа проверки',
  'Norwegian (Norsk)': 'норвежский язык (Norsk)',
  'Operator Task Based Verification': 'Проверка на основе оператора',
  'Operator Verification': 'Проверка оператора',
  'Orientation': 'ориентация',
  'Paper Size': 'Размер бумаги',
  'Physical Location': 'Физическое местонахождение',
  'Please note: Changing these options prepares the map for printing.': 'Внимание: Изменение этих параметров готовит карту к печати.',
  'Portrait': 'Вертикальная',
  'Portuguese (Português)': 'Португальский (Português, Brasil)',
  'Potential Fatality Incident - Energy': 'Потенциальный инцидент -',
  'Potential Fatality Incident - Zero Energy': 'Потенциальный инцидент с жизненным циклом - нулевая энергия',
  'Print': 'Печать',
  'Print Data': 'Печать данных',
  'Print Map': 'Печать карты',
  'Print Options': 'Варианты печати',
  'Print Preview': 'Предварительный просмотр',
  'Rail Collision': 'Железнодорожное столкновение',
  'Rail Impact on Person': 'Влияние рельса на человека',
  'Repeated Potential Fatality Incident - Energy': 'Повторите инцидент с энергией',
  'Repeated Potential Fatality Incident - Zero Energy': 'Повторите инцидент с нулевой энергией',
  'Russian (русский)': 'русский (русский)',
  'Select / Deselect All': 'Выбрать / Отменить выбор',
  'Select a Company Structure': 'Выберите структуру компании',
  'Select a GeoArea': 'Выберите GeoArea',
  'Select a Site': 'Выберите сайт',
  'Serbian (Српски)': 'сербский (Српски)',
  'Site': 'сайт',
  'Slope Failure': 'Ошибка наклона',
  'Sorry, we couldn\'t load incidents. Please try reloading the page.': 'К сожалению, мы не смогли загрузить инциденты. Попробуйте перезагрузить страницу.',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'К сожалению, мы не смогли загрузить проверки. Попробуйте перезагрузить страницу',
  'Spanish (Español)': 'Испанский (Español)',
  'Struck by Falling Object': 'Пораженный падающим предметом',
  'Supervisor Task Based Verification': 'Контрольная проверка на основе супервизора',
  'Supervisor Verification': 'Проверка супервизора',
  'Surface': 'поверхность',
  'US Legal': 'Законодательство США',
  'US Letter': 'Письмо США',
  'Unauthorised': 'неразрешенный',
  'Uncontrolled Load During Lifting': 'Неконтролируемая нагрузка при подъеме',
  'Uncontrolled Release of Energy': 'Неконтролируемый выпуск энергии',
  'Underground Fire': 'Подземный огонь',
  'Underground Fire or Explosion': 'Подземный огонь или взрыв',
  'Underground Hazardous Atmosphere': 'Подземная Опасная Атмосфера',
  'Underground Hoisting': 'Подземный подъем',
  'Underground Inrush': 'Подземный Наводнение',
  'Underground Rock Fall': 'Подземный рок-осень',
  'Unplanned Initiation of Explosives': 'Незапланированное инициирование взрывчатых веществ',
  'Vehicle Collision (Heavy and Light)': 'Столкновение транспортных средств (тяжелый и легкий)',
  'Vehicle Collision or Rollover': 'Столкновение или опрокидывание автомобиля',
  'Vehicle Impact on Person': 'Влияние автомобиля на человека',
  'Vehicle Incident': 'Инцидент с автомобилем',
  'Vehicle Pedestrian Interaction - Surface': 'Взаимодействие транспортного средства с пешеходом - Поверхность',
  'Vehicle Pedestrian Interaction - Underground': 'Взаимодействие транспортных средств с пешеходом - подземный',
  'Verification Date': 'Дата верификации',
  'Verification Details': 'Детали проверки',
  'Verification Type': 'Тип проверки',
  'Verifier': 'контрольник',
  'View Incident': 'Просмотр инцидента',
  'View Verification': 'Просмотр проверки',
  'Warning': 'Предупреждение',
  'Finnish (Suomalainen)': 'финский (Suomalainen)',
  'You do not have access to this application. Please contact your site administrator': 'У вас нет доступа к этому приложению. Пожалуйста, свяжитесь с администратором вашего сайта',
  'You will have to repeat these settings once you hit "Print" below': 'После нажатия кнопки "Печать" внизу вам придется повторно ввести эти настройки',
  'Indonesian (Bahasa Indonesia)': 'индонезийский (Bahasa Indonesia)'
};
