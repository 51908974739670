module.exports = {
"A3": "A3",
"A4": "A4",
"A5": "A5",
"All Sites": "すべてのサイト",
"Assisting Verifiers": "検証者の支援",
"COVID-19 (Coronavirus)": "COVID-19 (コロナウイルス)",
"Cannot load GeoAreas": "ジオエリアを読み込めません",
"Cannot retrieve Critical Risks": "重大なリスクを取得できません",
"Cannot retrieve Go To list": "Go To リストを取得できません",
"Cannot retrieve company list.": "会社リストを取得できません。",
"Cannot retrieve map overlays": "地図オーバーレイを取得できません",
"Chinese Simplified": "中国語（簡体字",
"Chinese Simplified (简化字)": "Chinese Simplified (简化字)",
"Coach": "コーチ",
"Company Structure": "会社の構造",
"Compliant Verification": "準拠検証",
"Compliant Verification Group": "準拠検証グループ",
"Confined Spaces": "限られたスペース",
"Contact with Electricity": "「電気との接触」",
"Contact with Molten Material": "「溶融物との接触」",
"Coordinates": "座標",
"Critical Control": "クリティカルコントロール",
"Critical Control Status": "クリティカル コントロール ステータス",
"Critical Risk": "重大なリスク",
"Date Range": "日付範囲",
"Drowning": "溺死",
"Dutch (Nederlands)": "オランダ語 (オランダ)",
"English (English)": "「英語（英語）」",
"Entanglement and Crushing": "「もつれと粉砕」",
"Entanglement in Rotating Equipment": "「回転機器への巻き込み」",
"Error": "エラー",
"Event in a Confined Space": "「限られたスペースでのイベント」",
"Explosives Handling": "「爆発物の取り扱い」",
"Exposure to Chemicals that Burn": "「燃焼する化学物質への暴露」",
"Exposure to Hazardous Substances": "「有害物質への暴露」",
"Exposure to Hazardous Substances (Chronic)": "「有害物質への曝露（慢性）」",
"Exposure to Thermal Extremes": "「極端な熱への暴露」",
"Fall from Height": "「高所から落下」",
"Fall of Ground - Surface": "「地面の落下 - 表面」",
"Fall of Ground - Underground": "Fall of Ground - Underground",
"Falling Objects": "落下物",
"Filter by Company Structure": "会社構造によるフィルター",
"Filter by Critical Risk": "重大なリスクでフィルター",
"Filter by Date": "検証日でフィルター",
"Filter by GeoArea": "地理エリアでフィルター",
"Filter by Site": "サイトでフィルター",
"Filter by Site Level": "サイトレベルでフィルター",
"Filter by Verification Type": "検証タイプによるフィルター",
"French (Français)": "フランス語 (Français)",
"GeoArea": "ジオエリア",
"German (Deutsch)": "ドイツ語 (Deutsch)",
"Go": "行け",
"Goto": "「後藤」",
"Goto Coordinates": "座標に移動",
"Goto GeoArea": "「ジオエリアへ」",
"Grizzly Bear Interactions": "グリズリーベアの相互作用",
"Hazardous Material": "危険物",
"Heavy Vehicle Event - Rollover Over Edge": "重車両イベント - ロールオーバーオーバーエッジ",
"Icelandic (Íslensku)": "アイスランド語 (Íslensku)",
"Incident Date": "事件日",
"Incident Details": "インシデントの詳細",
"Incident During Tire Handling": "「タイヤ取り扱い中の事故」",
"Italian (Italiano)": "「イタリアン（イタリアン）」",
"Landscape": "風景",
"Language": "言語",
"Legend": "伝説",
"Level": "レベル",
"Lifting Operations": "「持ち上げ操作」",
"Loading Map Data": "読み込み中",
"Location": "位置",
"Logout": "ログアウト",
"Malaria": "マラリア",
"Manager Verification": "マネージャーの確認",
"Message": "メッセージ",
"Mongolian (Монгол хэл)": "モンゴル語 (Монгол хэл)",
"Mooring Operations": "「係留作業」",
"No incident results found. Please try changing the date range.": "インシデントの結果は見つかりませんでした。日付範囲を変更してみてください。",
"No verification results found. Please try changing the date range.": "検証結果が見つかりませんでした。日付範囲を変更してみてください。",
"Non Compliant Verification": "非準拠の検証",
"Non Compliant Verification Group": "非準拠検証グループ",
"Norwegian (Norsk)": "「ノルウェー語（ノルスク）」",
"Operator Task Based Verification": "オペレータ タスク ベースの検証",
"Operator Verification": "「オペレーター検証」",
"Orientation": "オリエンテーション",
"Paper Size": "用紙サイズ",
"Physical Location": "物理的な位置",
"Please note: Changing these options prepares the map for printing.": "注意: これらのオプションを変更すると地図を印刷する準備が整います。",
"Portrait": "ポートレート",
"Portuguese (Português)": "ポルトガル語 (Portuguêsブラジル)",
"Potential Fatality Incident - Energy": "「エネルギーのある事件」",
"Potential Fatality Incident - Zero Energy": "「エネルギーゼロ事件」",
"Print": "印刷",
"Print Data": "印刷データ",
"Print Map": "このページを印刷",
"Print Options": "印刷オプション",
"Print Preview": "印刷プレビュー",
"Rail Collision": "「レール衝突」",
"Rail Impact on Person": "「人への鉄道の影響」",
"Repeated Potential Fatality Incident - Energy": "「エネルギーでインシデントを繰り返す」",
"Repeated Potential Fatality Incident - Zero Energy": "「エネルギーゼロで事件を繰り返す」",
"Russian (русский)": "ロシア語 (русский)",
"Select / Deselect All": "すべて選択/選択解除",
"Select a Company Structure": "会社構造を選択",
"Select a GeoArea": "ジオエリアを選択",
"Select a Site": "サイトを選択",
"Serbian (Српски)": "セルビア語 (Српски)",
"Site": "サイト",
"Slope Failure": "スロープの失敗",
"Sorry we couldn't load incidents. Please try reloading the page": "申し訳ありませんがインシデントを読み込めませんでした。ページをリロードしてみてください",
"Sorry we couldn't load verifications. Please try reloading the page": "申し訳ありませんが検証を読み込めませんでした。ページをリロードしてみてください",
"Spanish (Español)": "スペイン語 (Español)",
"Struck by Falling Object": "「落下物に打たれる」",
"Supervisor Task Based Verification": "スーパーバイザー タスク ベースの検証",
"Supervisor Verification": "「監督者の確認」",
"Surface": "水面",
"US Legal": "米国法務",
"US Letter": "米国の手紙",
"Unauthorised": "無許可",
"Uncontrolled Load During Lifting": "「持ち上げ中の制御されていない負荷」",
"Uncontrolled Release of Energy": "「制御されていないエネルギーの放出」",
"Underground Fire": "「アンダーグラウンドファイア」",
"Underground Fire or Explosion": "「地下火災または爆発」",
"Underground Hazardous Atmosphere": "「地下の危険な雰囲気」",
"Underground Hoisting": "「地下巻き上げ」",
"Underground Inrush": "「地下突入」",
"Underground Rock Fall": "「アンダーグラウンド・ロック・フォール」",
"Unplanned Initiation of Explosives": "「爆発物の計画外の開始」",
"Vehicle Collision (Heavy and Light)": "「車両衝突（重軽）」",
"Vehicle Collision or Rollover": "「車両の衝突または転覆」",
"Vehicle Impact on Person": "「人への車両の影響」",
"Vehicle Incident": "「車両事故」",
"Vehicle Pedestrian Interaction - Surface": "「車両歩行者の相互作用 - 表面」",
"Vehicle Pedestrian Interaction - Underground": "「車両歩行者の相互作用 - 地下」",
"Verification Date": "検証日",
"Verification Details": "検証の詳細",
"Verification Type": "検証タイプ",
"Verifier": "検証者",
"View Incident": "インシデントを表示",
"View Verification": "検証を表示",
"Warning": "警告",
"Finnish (Suomalainen)": "「フィンランド語（スオマライネン）」",
"Indonesian (Bahasa Indonesia)": "「インドネシア語（インドネシア語）」",
"You do not have access to this application. Please contact your site administrator.": "このアプリケーションへのアクセス権がありません。サイト管理者に連絡してください。",
"You will have to repeat these settings once you hit \"Print\" below": "「下の「印刷」をクリックしたらこれらの設定を繰り返す必要があります」"
};
