import { connect } from "react-redux";

import Component from "../Components/LanguageSelector";

const mapStateToProps = state => {
  return {
    t: state.language.t.language,
    isMobile: state.ui.isMobile,
    locale: state.language.locale
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguage(locale) {
      dispatch({ type: "LANGUAGE_SET", locale });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
