module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': '所有站点',
  'Assisting Verifiers': '协助验证者',
  'COVID-19 (Coronavirus)': 'COVID-19 (新冠病毒)',
  'Cannot load GeoAreas': '无法加载GeoAreas',
  'Cannot retrieve Critical Risks': '无法检索重大风险',
  'Cannot retrieve Go To list': '无法检索“转到”列表',
  'Cannot retrieve company list.': '无法检索公司列表。',
  'Cannot retrieve map overlays': '无法检索地图叠加层',
  'Chinese, Simplified': '简体中文',
  'Chinese, Simplified (简化字)': '简体中文',
  'Coach': '教练',
  'Company Structure': '公司结构',
  'Compliant Verification': '违规验证',
  'Compliant Verification Group': '合规验证小组',
  'Confined Spaces': '密闭空间',
  'Contact with Electricity': '接触电流',
  'Contact with Molten Material': '接触熔融材料',
  'Coordinates': '座标',
  'Critical Control': '关键控制措施',
  'Critical Control Status': '关键控制措施状态',
  'Critical Risk': '重大风险',
  'Date Range': '日期范围',
  'Drowning': '溺水',
  'Dutch (Nederlands)': '荷兰人 (Nederlands)',
  'English (English)': '英语 (English)',
  'Entanglement and Crushing': '缠绕和碾压',
  'Entanglement in Rotating Equipment': '纠缠在旋转设备中',
  'Error': '错误',
  'Event in a Confined Space': '密闭空间中的事件',
  'Explosives Handling': '炸药处理',
  'Exposure to Chemicals that Burn': '接触会燃烧的化学物质',
  'Exposure to Hazardous Substances': '接触有害物质',
  'Exposure to Hazardous Substances (Chronic)': '接触有害物质（长期）',
  'Exposure to Thermal Extremes': '暴露于极端温度',
  'Fall from Height': '高处坠落',
  'Fall of Ground - Surface': '地面跌落-表面',
  'Fall of Ground - Underground': '地面倒塌-地下',
  'Falling Objects': '坠落物体',
  'Filter by Company Structure': '按公司结构筛选',
  'Filter by Critical Risk': '按重大风险筛选',
  'Filter by Date': '按验证日期过滤',
  'Filter by GeoArea': '按GeoArea筛选',
  'Filter by Site': '按站点筛选',
  'Filter by Site Level': '按站点级别筛选',
  'Filter by Verification Type': '按验证类型筛选',
  'French (Français)': '法语 (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': '德语 (Deutsch)',
  'Go': '去',
  'Goto': '去',
  'Goto Coordinates': '转到坐标',
  'Goto GeoArea': '转到地理区域',
  'Grizzly Bear Interactions': '灰熊互动',
  'Hazardous Material': '危险材料',
  'Heavy Vehicle Event - Rollover, Over Edge': '重型车辆事件-翻车，越过边缘',
  'Icelandic (Íslensku)': '冰岛的 (Íslensku)',
  'Incident Date': '事件日期',
  'Incident Details': '事件详细信息',
  'Incident During Tire Handling': '轮胎处理中的事故',
  'Italian (Italiano)': '义大利文 (Italiano)',
  'Landscape': '景观',
  'Language': '语言',
  'Legend': '图例',
  'Level': '级别',
  'Lifting Operations': '起重作业',
  'Loading Map Data': '载入中',
  'Location': '位置',
  'Logout': '注销',
  'Malaria': '疟疾',
  'Manager Verification': '经理验证',
  'Message': '消息',
  'Mongolian (Монгол хэл)': '蒙古文 (Монгол хэл)',
  'Mooring Operations': '系泊作业',
  'No incident results found. Please try changing the date range.': '未找到事件结果。请尝试更改日期范围。',
  'No verification results found. Please try changing the date range.': '未找到验证结果。请更改日期范围。',
  'Non Compliant Verification': '违规验证',
  'Non Compliant Verification Group': '违规验证小组',
  'Norwegian (Norsk)': '挪威 (Norsk)',
  'Operator Task Based Verification': '基于操作员任务的验证',
  'Operator Verification': '操作员验证',
  'Orientation': '取向',
  'Paper Size': '纸张尺寸',
  'Physical Location': '物理位置',
  'Please note: Changing these options prepares the map for printing.': '请注意：更改这些选项将准备打印地图。',
  'Portrait': '肖像',
  'Portuguese (Português)': '葡萄牙语 (Português, Brasil)',
  'Potential Fatality Incident - Energy': '能源事故',
  'Potential Fatality Incident - Zero Energy': '零能耗事件',
  'Print': '打印',
  'Print Data': '打印数据',
  'Print Map': '打印此页',
  'Print Options': '打印预览',
  'Print Preview': '打印预览',
  'Rail Collision': '轨道碰撞',
  'Rail Impact on Person': '铁路对人员的影响',
  'Repeated Potential Fatality Incident - Energy': '重复能源事故',
  'Repeated Potential Fatality Incident - Zero Energy': '零能耗重复事件',
  'Russian (русский)': '俄语 (русский)',
  'Select / Deselect All': '选择/取消选择全部',
  'Select a Company Structure': '选择一种公司结构',
  'Select a GeoArea': '选择一个地理区域',
  'Select a Site': '选择一个站点',
  'Serbian (Српски)': '塞尔维亚 (Српски)',
  'Site': '站点',
  'Slope Failure': '边坡破坏',
  'Sorry, we couldn\'t load incidents. Please try reloading the page': '对不起，未能加载事件。请尝试重新加载页面。',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': '对不起，未能加载验证。请尝试重新加载页面',
  'Spanish (Español)': '西班牙语 (Español)',
  'Struck by Falling Object': '被坠落物体击中',
  'Supervisor Task Based Verification': '基于主管任务的验证',
  'Supervisor Verification': '主管验证',
  'Surface': '表面',
  'US Legal': '美国法律',
  'US Letter': '美国信',
  'Unauthorised': '未经授权',
  'Uncontrolled Load During Lifting': '提升过程中不受控制的负载',
  'Uncontrolled Release of Energy': '不受控制的能量释放',
  'Underground Fire': '地下火',
  'Underground Fire or Explosion': '地下着火或爆炸',
  'Underground Hazardous Atmosphere': '地下危险环境',
  'Underground Hoisting': '地下吊装',
  'Underground Inrush': '地下突入',
  'Underground Rock Fall': '地下岩崩',
  'Unplanned Initiation of Explosives': '计划外爆炸物起爆',
  'Vehicle Collision (Heavy and Light)': '车辆碰撞（重型和轻型',
  'Vehicle Collision or Rollover': '车辆碰撞或侧翻',
  'Vehicle Impact on Person': '车辆对人的影响',
  'Vehicle Incident': '车辆事故',
  'Vehicle Pedestrian Interaction - Surface': '车辆行人互动-表面',
  'Vehicle Pedestrian Interaction - Underground': '车辆行人互动-地下',
  'Verification Date': '验证日期',
  'Verification Details': '验证详情',
  'Verification Type': '验证类型',
  'Verifier': '验证者',
  'View Incident': '查看事件',
  'View Verification': '查看验证',
  'Warning': '警告',
  'Finnish (Suomalainen)': '芬兰 (Suomalainen)',
  'You do not have access to this application. Please contact your site administrator.': '您无法访问此应用程序。请联系您的站点管理员。',
  'You will have to repeat these settings once you hit "Print" below': '点击下面的"打印"后，您将不得不重复这些设置',
  'Indonesian (Bahasa Indonesia)': '印度尼西亚 (Bahasa Indonesia)'
};
