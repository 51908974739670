module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Сви Локалитети',
  'Assisting Verifiers': 'Помоћни верификатори',
  'COVID-19 (Coronavirus)': 'COVID-19 (Корона вирус)',
  'Cannot load GeoAreas': 'Страница GeoAreas се не може учитати',
  'Cannot retrieve Critical Risks': 'Критични ризици се не могу преузети',
  'Cannot retrieve Go To list': 'Иди на листу се не може преузети',
  'Cannot retrieve company list.': 'Листа предузећа се не може преузети.',
  'Cannot retrieve map overlays': 'Слојеви мапе се не могу преузети',
  'Chinese, Simplified': 'Кинески, поједностављен',
  'Chinese, Simplified (简化字)': 'Кинески, поједностављен (简化字)',
  'Coach': 'Тренер',
  'Company Structure': 'Структура предузећа',
  'Compliant Verification': 'Верификација усаглашености',
  'Compliant Verification Group': 'Група за верификацију усаглашености',
  'Confined Spaces': 'Затворени простори',
  'Contact with Electricity': 'Контакт са електричном струјом',
  'Contact with Molten Material': 'Контакт са истопљеним материјалом',
  'Coordinates': 'Координате',
  'Critical Control': 'Критична контрола',
  'Critical Control Status': 'Статус критичне контроле',
  'Critical Risk': 'Критични ризик',
  'Date Range': 'Опсег датума',
  'Drowning': 'Утапање',
  'Dutch (Nederlands)': 'Холандски (Nederlands)',
  'English (English)': 'Енглески (English)',
  'Entanglement and Crushing': 'Опасност од укљештења и смрскавања',
  'Entanglement in Rotating Equipment': 'Опасност од укљештења код ротирајућих елемената',
  'Error': 'Грешка',
  'Event in a Confined Space': 'Инцидент у затвореном простору',
  'Explosives Handling': 'Руковање експлозивним средствима',
  'Exposure to Chemicals that Burn': 'Изложеност хемикалијама koje изазивају опекотине на кожи',
  'Exposure to Hazardous Substances': 'Изложеност опасним супстанцама',
  'Exposure to Hazardous Substances (Chronic)': 'Изложеност опасним супстанцама (хронично)',
  'Exposure to Thermal Extremes': 'Изложеност екстремно високим или ниским температурама',
  'Fall from Height': 'Опасност од падa са висине',
  'Fall of Ground - Surface': 'Обрушавање земље - Површинско',
  'Fall of Ground - Underground': 'Обрушавање земље - Подземно',
  'Falling Objects': 'Опасност од пада предмета са висине',
  'Filter by Company Structure': 'Филтрирај према структури предузећа',
  'Filter by Critical Risk': 'Филтрирај према критичном ризику',
  'Filter by Date': 'Филтрирај према датуму',
  'Filter by GeoArea': 'Филтрирај према GeoArea',
  'Filter by Site': 'Филтрирај према локалитету',
  'Filter by Site Level': 'Филтрирај према нивоу локалитета',
  'Filter by Verification Type': 'Филтрирај према врсти верификације',
  'French (Français)': 'Француски (Français)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Немачки (Deutsch)',
  'Go': 'Иди',
  'Goto': 'Иди на',
  'Goto Coordinates': 'Иди на координате',
  'Goto GeoArea': 'Иди на GeoArea',
  'Grizzly Bear Interactions': 'Интеракције Grizzly Bear',
  'Hazardous Material': 'Опасне материје',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Инцидент са тешким возилом - Превртање, преко ивице пута',
  'Icelandic (Íslensku)': 'Исландски (Íslensku)',
  'Incident Date': 'Датум инцидента',
  'Incident Details': 'Детаљније информације о инциденту',
  'Incident During Tire Handling': 'Инцидент у току замене гуме',
  'Italian (Italiano)': 'Италијански (Italiano)',
  'Landscape': 'Хоризонтална оријентација приказа (пејзаж)',
  'Language': 'Језик',
  'Legend': 'Легенда мапе',
  'Level': 'Ниво',
  'Lifting Operations': 'Операције дизања',
  'Loading Map Data': 'Учитавање података мапе',
  'Location': 'Локација',
  'Logout': 'Одјава',
  'Malaria': 'Маларија',
  'Manager Verification': 'Менаџер за верификацију',
  'Message': 'Порука',
  'Mongolian (Монгол хэл)': 'Монголски (Монгол хэл)',
  'Mooring Operations': 'Операција везивања',
  'No incident results found. Please try changing the date range.': 'Нема резултата претраге о инциденту. Молимо покушајте са измењеним опсегом датума',
  'No verification results found. Please try changing the date range.': 'Нема резултата верификације. Молимо покушајте поново са измењеним опсегом датума',
  'Non Compliant Verification': 'Верификација неусаглашености',
  'Non Compliant Verification Group': 'Група за верификацију неусаглашености',
  'Norwegian (Norsk)': 'Норвешки (Norsk)',
  'Operator Task Based Verification': 'Верификација оператера на основу задатка',
  'Operator Verification': 'Верификација оператера',
  'Orientation': 'Оријентација',
  'Paper Size': 'Величина папира',
  'Physical Location': 'Физичка локација',
  'Please note: Changing these options prepares the map for printing.': 'Напомена: Изменом ових опција припремате мапу за штампање.',
  'Portrait': 'Вертикална оријентација приказа (портрет)',
  'Portuguese (Português)': 'Португалски (Português, Бразил)',
  'Potential Fatality Incident - Energy': 'Инцидент са потенцијално фаталним исходом - Енергија',
  'Potential Fatality Incident - Zero Energy': 'Инцидент са потенциално фаталним исходом - Нулtа енергијa',
  'Print': 'Штампање',
  'Print Data': 'Штампај датум',
  'Print Map': 'Штампај мапу',
  'Print Options': 'Опције за штампање',
  'Print Preview': 'Приказ за штампање',
  'Rail Collision': 'Судар vozova',
  'Rail Impact on Person': 'Ударac воза u čoveka',
  'Repeated Potential Fatality Incident - Energy': 'Поновљен инцидент са потенцијално фаталним исходом - Енергија',
  'Repeated Potential Fatality Incident - Zero Energy': 'Поновљен инцидент са потенцијално фаталним исходом - Нулта енергија',
  'Russian (русский)': 'Руски (русский)',
  'Select / Deselect All': 'Изабрати / поништити целу селекцију',
  'Select a Company Structure': 'Изабрати структуру предузећа',
  'Select a GeoArea': 'Изабрати неку GeoArea',
  'Select a Site': 'Одабрати неки Локалитет',
  'Serbian (Српски)': 'Српски (Српски)',
  'Site': 'Локалитет',
  'Slope Failure': 'Обрушавање косине',
  'Sorry, we couldn\'t load incidents. Please try reloading the page': 'Инциденти не могу да се учитају. Молимо поново покушајте да учитате ову страницу',
  'Sorry, we couldn\'t load verifications. Please try reloading the page': 'Верификације не могу да се учитају. Молимо поново покушајте да учитате ову страницу',
  'Spanish (Español)': 'Шпански (Español)',
  'Struck by Falling Object': 'Погођен предметом који је пао са висине',
  'Supervisor Task Based Verification': 'Верификација супервизора на основу задатка',
  'Supervisor Verification': 'Верификација супервизора',
  'Surface': 'Површински',
  'US Legal': 'US Legal',
  'US Letter': 'US Brief',
  'Unauthorised': 'Неовлашћено',
  'Uncontrolled Load During Lifting': 'Неконтролисан терет при подизању',
  'Uncontrolled Release of Energy': 'Неконтролисано ослобађање енергије',
  'Underground Fire': 'Пожар под земљом',
  'Underground Fire or Explosion': 'Подземни пожар или експлозија',
  'Underground Hazardous Atmosphere': 'Затрована атмосфера под земљом',
  'Underground Hoisting': 'Подизање под земљом',
  'Underground Inrush': 'Подземно надирање воде',
  'Underground Rock Fall': 'Подземно обрушавање стена',
  'Unplanned Initiation of Explosives': 'Непланско активирање експлозивних супстанци',
  'Vehicle Collision (Heavy and Light)': 'Судар возила (тешких и лаких)',
  'Vehicle Collision or Rollover': 'Судар возила или превртање',
  'Vehicle Impact on Person': 'Ударац возилa у човека',
  'Vehicle Incident': 'Incident sa vozilom',
  'Vehicle Pedestrian Interaction - Surface': 'Интеракција пешака и возила - Површина',
  'Vehicle Pedestrian Interaction - Underground': 'Интеракција возила и пешака - Под земљом',
  'Verification Date': 'Датум верификације',
  'Verification Details': 'Подаци верификације',
  'Verification Type': 'Врста верификације',
  'Verifier': 'Верификатор',
  'View Incident': 'Приказ инцидента',
  'View Verification': 'Приказ верификације',
  'Warning': 'Упозорење',
  'Finnish (Suomalainen)': 'фински (Suomalainen)',
  'You do not have access to this application. Please contact your site administrator.': 'Немате приступ овој апликацији. Молимо контактирајте свог сајт администратора',
  'You will have to repeat these settings once you hit "Print" below': 'Мораћете да поновите ове поставке пошто притиснете доњу команду "Штампај"',
  'Indonesian (Bahasa Indonesia)': 'индонезијски (Bahasa Indonesia)'
};
